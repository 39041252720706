.info-block-top{
  background:#fff;
  display:none;
  padding-top:100px;

  @media (max-width:768px){
    padding-top:80px;
  }

  .no-gutters{
    padding:0;
  }

  .service-col{
    padding:0;
  }

  .content-side{
    padding:50px 75px 75px 75px;

    @media (max-width:992px){
        padding:25px;
    }

    h1{
      margin-top:0;
      color:#AA63A8;
      text-transform:uppercase;
      font-size:42px;
      font-weight:700;
      font-family:$font-heading;

      @media (max-width:992px){
        margin-top:25px;
      }

    }
    p, ul, li{
        font-family:$font-primary;
        font-size:18px;
    }
  }
  .image-side{

    img{
      width: 100%;
      height: 100%;
      object-fit:cover;
      max-width:100%;
      max-height:100%;
    }

  }

}


.service-holder {
  padding-bottom:80px;

  &.activated{
      padding-top:80px;
      background:white;
      @media (max-width:992px){
        padding-top:25px;
      }
  }

  @media (max-width:992px){
    padding-bottom:25px;
  }

  .services-blocks {

    .darken-image-panel{
      background: rgba(0,0,0,0.2);
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      z-index: 0;
      left: 0;
      top: 0;
    }

    .service-item {
      margin-bottom: 25px;
      padding: 25px;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: grey;

      @media (max-width:992px){
        margin-top:25px;
      }

      &.full-height {
        height: 700px;
        @media (max-width:992px){
          height: 400px;
        }
      }
      &.half-height {
        height: 338px;
        @media (max-width:992px){
          height: 400px;
        }
      }

      a {
        &:hover, &:active, &:focus {
          text-decoration: none;
        }
      }

      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: 0.3s;
        background: $brand-secondary;

        img {
          position: absolute;
          bottom: 25px;
          right: 25px;
        }
      }
      h2 {
        color: #fff;
        margin: 0;
        text-transform: uppercase;
        font-size: 32px;
        z-index: 990;
        position: relative;
        width: 75%;
      }

      &:hover {
        .overlay {
          opacity: 0.9;
        }
      }

    }
  }
}

.service-fix {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10200;
  background: rgba(48, 62, 72, 0.9);
  padding: 20px $padding-xs;

  @media(max-width: $screen-xs-max) {
    #brand {
      width: 66.6666666667%;
      float: left;
      img {
        max-width: 100%;
        float: left;
        max-height: 40px;
        height: auto;
      }
    }
    #toolbar {
      width: 33.3333333332%;
      float: right;

      .social, form {
        display: none;
      }

    }
  }
  @media (min-width: $screen-sm-min) {
    padding: 20px $padding-sm;
  }

  @media (min-width: $screen-md-min) {
    padding: 20px $padding-md;
  }

  .brand {
    background: transparent;
  }
  .social {
    background: transparent;
    a {
      height: 40px;
    }
  }

  .fa-search {
    font-size: 1.6em;
    height: 40px;
  }

  .search-form {
    .search-field {
      height: 40px;
      font-size: 12px;
    }
  }

  .btn-toggle {
    width: 40px;
    height: 40px;
  }
  @media(max-width: $screen-xs-max) {
    .btn-toggle {
      &.cbp-spmenu-push-toleft {
        left: -250px;
      }
    }
  }
}

.single-service{

}
