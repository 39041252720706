body {
    font-family: $font-primary;
    z-index: 10;
    position: relative;
    background-color: #fff;
    opacity: 1;
    transition: 1s opacity;
    &.gray{
        background: #f1f1f1;
    }
    header, .wrap, footer, .covid-banner {
        opacity: 1;
        transition: 1s opacity;
        transition-delay:1s;
    }
}

body.fade-out {
    header, .wrap, footer, .covid-banner {
        opacity: 0;
        transition: none;
    }
}

body.fade-out-anim {
    header, .wrap, footer, .covid-banner {
        opacity: 0;
        transition: 0.5s opacity;
    }
}

.fade-out-loader {
  display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  text-align: center;
  font-size: 40px;
  z-index: -10;
  top: 140px;
  padding-top: 10vh;
  opacity: 0;
  transition: 1s opacity ease;
  transition-delay: 0s ;
  @media (min-width: $screen-lg-min) {
    top: 30vh;
  }
    p {
        text-align:center;
        color: rgba(0,0,0,0.8);
        font-size:12px; 
        margin-bottom:5px;
        
    }
    .graphic {
        width:200px;
        height:auto;
        display:block;
        margin:0 auto;
        position:relative;
        &:after {   
            content:'';
            position:absolute;
            width: 70px;
            height: 300px;
            background: #fff;
            top: -120px;
            filter: blur(10px);
            opacity:0;
            transform:rotate(-45deg);
            animation-name: loader;
            animation-duration: 1s;
            animation-timing-function: ease-in;
        }
    }
}

body.fade-out, body.fade-out-anim {
  .fade-out-loader {
    opacity: 1;
    z-index: 10;
  }
}


.gneiss-container {
    @extend .container-fluid;

    position: relative;
    padding: 0 $padding-xs;

    @media (min-width: $screen-sm-min) {
        padding: 0 $padding-sm;
    }

    @media (min-width: 992px) {
        padding: 0 250px;
    }
}

.search .search__content .gneiss-container {
    @media (min-width: 992px) {
        padding: 0 50px;
    }
    @media (min-width: 1500px) {
        padding: 0 250px;
    }
}

section {
    background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-heading;
    color: #353D47;
}

@media(max-width: $screen-xs-max) {
    h1, h2 {
        font-size: 34px;
    }
}

h2 {
    @media(min-width: $screen-md-min) {
        font-size: 42px;
    }
}

hr {
    margin: 40px auto;
    border-color: #000;
    width: 75px;
}

a {
    color: $brand-highlight;
    font-weight: 600;
}

p {
    font-family: $font-primary;
    font-size: 18px;
    margin: 0 0 25px;
}

ul{
    font-size: 18px;
}

.container {
    @media (min-width: $screen-sm-min) {
        width: 650px;
    }

    @media (min-width: $screen-md-min) {
        width: 1000px;
    }

    &.404 {
        padding: 40px 15px;
    }
}

.container--small {
    @extend .container;
    padding: 60px 15px;
}

.half-container {
    display: inline-block;
    width: 50%;
    height: 100%;

    @media (max-width: $screen-xs-max) {
        height: 50%;
        width: 100%;
    }
}

.cover-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.latest-panel {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max){
        //min-height:365px;
    }
    @media(min-width: $screen-md-min){
        margin-bottom: 20px;
    }
    @media(min-width: $screen-lg-min){
        //height: 400px;
    }
    .latest-panel__image {
        @media (max-width: $screen-xs-max) {
            height: 200px;
        }
        @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
            height: 300px;
        }

        height: 100%;
    }

    .latest-panel__content {
        padding: 30px 40px;
        @media(min-width: $screen-lg-min) and (max-width: 1500px){
            padding: 15px 25px;
        }
        height: 100%;

        h3 {
            color: #000;
            font-weight: 600;
            font-family: $font-heading;
            font-size: 24px;
        }

        p {
            margin-bottom: 20px;

            @media (max-width: $screen-xs-max) {
                margin-bottom: 50px;
            }
        }

        .btn-secondary {
            padding: 12px 35px;
            text-align: center;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            display: block;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.latest-panel__heading {
    position: absolute;
    text-transform: uppercase;
    font-size: 16px;
    color: #fff;
    font-family: $font-heading;
    padding: 10px 15px;
    z-index: 10;
    font-weight: 600;

    &.heading--events-upcoming {
        background-color: $brand-primary3;
    }

    &.heading--news, &.heading--events {
        background-color: $brand-primary2;
    }

    &.heading--media {
        background-color: $brand-secondary5;
    }
}

.event-type{
    position:relative;
    text-transform: uppercase;
    font-size: 16px;
    color: #fff;
    font-family: $font-heading;
    padding: 10px 15px;
    z-index: 10;
    font-weight: 600;
    margin-bottom:20px;
    display:inline-block;

    sup {
        text-transform: none;
    }

    &.heading--events-upcoming {
        background-color: $brand-primary3;
    }

    &.heading--news, &.heading--events {
        background-color: $brand-primary2;
    }

    &.heading--media {
        background-color: $brand-secondary5;
    }
}

.post-nav{
    display:flex;
    justify-content:center;

    .pager {
        margin: 20px 0px 20px auto;
    }

    .previous,
    .nav-previous{
        margin-right:10px;
        a {
            background-color: white;
            color: $brand-primary;
        }
    }
    .next,
    .nav-next {
        a {
            background-color: $brand-secondary;
            color: white;
        }
    }
    a {
        border-radius: 5px !important;
        padding: 15px 25px !important;
        font-family: 'Proza Libre', sans-serif;
        text-transform: uppercase;
    }
}

.post-nav-archive {
    margin: 20px 0;
    height: fit-content;
    padding: 20px;
}

.search-content-block{
    margin-bottom:25px;

    @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-bottom:0;
    }

    img{
        transition:0.5s;
        &:hover{
            transform:scale(1.05);
        }
    }

}

.latest-panel--small {
    border:1px solid #DCDCDC;
    height: 100%;
    width: 100%;
    display: inline-block;
    background-color: #fff;
    overflow: hidden;
    padding: 20px;
    @media (max-width: $screen-xs-max) {
        margin-top: 15px;
    }
    img {
        width: 100%;
    }
    .img-responsive{
        transition: ease all 0.5s;
        &:hover{
            transform:scale(1.05);
        }
    }
    .cover-image {
        @media(min-width: $screen-md-min) and (max-width: 1200px){
            height: 130px;
        }
        height: 200px;
        transition: ease all 0.5s;

        &:hover {
            transform: scale(1.05);
        }
    }

    .cover-image, img {
        border: 1px solid #dcdcdc;
    }

    .latest-panel__content--small {
        h3 {
            margin-top: 0;
            font-size: 14px;
            font-weight: 800;
            margin-bottom: 5px;
            @media(min-width: $screen-md-min) and (max-width: $screen-md-max){
                overflow: hidden;
            }
        }

        p {
            font-size: 14px;
            font-family: $font-primary;
            margin: 0;
        }

        .date {
            margin-top: 10px;
        }
    }
}

.cover-link__url {
    display: block;
    overflow: hidden;
}

.latest-links {
    font-family: $font-heading;
    text-align: center;
    padding: 30px 0;

    ul {
        margin-bottom: 0;

        a {
            text-transform: uppercase;
            font-size: 16px;
            color: #57caef;
        }
    }
}

.side-bar {
    padding: 0 15px;
    background-color: #fff;

    h2{
        margin-top: 0px;
        font-size: 36px;
    }

    li{
        list-style: none;
        font-size: 18px;
        margin-bottom: 5px;
    }
}
.modal{
    text-align: center;
    &:before {
        @media(min-width: $screen-sm-min) {
            display: inline-block;
            vertical-align: middle;
            content: " ";
            height: 100%;
        }
    }
}
.modal-content {
    border-radius: 0px;
}

.modal-backdrop {
    .in {
        opacity: 0.9;
    }
}

.modal-header {
    @extend .cover-image;
    background-image: url(../images/background-wash.jpg);

    h2 {
        color: #fff;
    }

    span {
        color: #fff;
    }

    .close {
        opacity: 0.5;
        color: $brand-secondary3;
        margin: 0;
        height: 34px;
        @media(min-width: $screen-sm-min) {
            height: 42px;
        }
        @media(min-width: $screen-md-min) {
            height: 60px;
        }

        &:hover{
            opacity: 0.75;
        }
    }
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.flex-control-nav {
    left: 0;
}

.swiper-wrapper {
    transition-timing-function: linear !important;
}

.cbp-spmenu {
    background: $brand-highlight;
}

.side-news {
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 12px;

    a {
        display: block;
        font-size: 16px;
        margin-bottom: 4px;
    }

    .side-image {
        display: block;
        width: 100%;
        @extend .cover-image;
    }

    img {
        border: 1px solid #dcdcdc;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    #date {
        color: #3b3b3b;
        font-weight: 600;
        font-size: 13px;
    }

    @media(min-width: $screen-sm-min){
        .side-image{
            height: 120px;
        }
    }
    @media(min-width: $screen-md-min) and (max-width: $screen-md-max){
        margin-bottom: 0;
        height: auto;
    }
}

.page-template-template-services {
    footer {
        margin-top: -10px;
    }
}

article.post{
    @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-top: 0;
        padding-top: 30px;
    }
}

.events-archive-custom{
    article.events{
        margin-bottom:25px;
    }
    .custom-pagination{
        width:100%;
        clear: both;
        text-align:center;
        margin-bottom:25px;
    }
}

.content--layout {
	pointer-events: auto;
	justify-content: center;
	align-content: center;
	grid-template-columns: 150px 150px 150px 150px; 
	grid-template-rows: 150px 75px 75px;
	grid-template-areas:	"... title title title"
							"author ... desc desc"
							"author ... link link";
}

@media screen and (max-width: 40em) {
	.content--layout {
		transform: scale3d(0.8,0.8,1);
	}
}

@media screen and (max-width: 36em) {
	.content--layout {
		transform: scale3d(0.7,0.7,1);
	}
}

@media screen and (max-width: 32em) {
	.content--layout {
		transform: scale3d(0.6,0.6,1);
	}
	.content__author,
	.content__desc,
	.content__link {
		font-size: 1.35em;
	}
}

@media screen and (max-width: 28em) {
	.content--layout {
		transform: scale3d(0.5,0.5,1);
	}
}

.content__title {
	grid-area: title;
	font-family: 'Proza Libre', sans-serif;
	letter-spacing: -0.025em;
	font-size: 100px;
	line-height: 100px;
	margin: 0;
	text-transform: uppercase;
	z-index: 100;
	cursor: default;
}
.content__title {
	transition: transform 0.3s ease-out;
}

/* layout-3 */
.content--layout-3 {
	grid-template-rows: 75px 75px 150px;
	grid-template-areas:	"... ... desc desc"
							"author title title title"
                            "author ... link link";
    &.content {
        position: relative;
        //display: grid;
    }
}

.content--layout-3 .content__img {
	grid-column: 1 / 3;
	grid-row: 1 / 3;
}

.content--layout-3 .content__desc {
	align-self: end;
}

.swiper-pagination-bullet-active {
    background: #CA37BC;
}

.block-tile {
    color:#333;
    font-weight:300;
    min-height: 100%;
    transition:0.3s all ease;
    .icon {
        display:inline-block;
        transition:0.6s all ease;
        transform:translateX(0);
    }
    .cta {
        font-size: 20px;
        padding-bottom:20px;
        display:block;
        transition:0.3s all ease;
        @media (min-width: 1500px) {
            position: absolute;
        }
        bottom: 0;
        svg {
            transition:0.3s all ease;
            transform:translateX(0);  
        }
    }
    
    &:hover{
        color: $brand-secondary;
        text-decoration:none;
        .icon{
            transform:translateX(10px);
        }
        .cta{
            color:#333;
            svg {
                transform:translateX(5px);  
            }  
        }
    }
}

.serviceGreen {
    color: $serviceGreen;
    .block-tile {
        background:transparent;
        &:hover{
            background: $serviceGreen;
            color:#fff;
            .icon{
                img {
                    filter: grayscale(1) brightness(800);
                }
            }
            .cta {
                color:lighten( $serviceGreen, 30% )
            }
        }
    }
}
.serviceGreen-bullets ul li:before {
    border-left-color: $serviceGreen !important;
}
.servicePurple {
    color: $servicePurple;
    .block-tile {
        background:transparent;
        &:hover{
            background: $servicePurple;
            color:#fff;
            .icon{
                img {
                    filter: grayscale(1) brightness(800);
                }
            }
            .cta {
                color:lighten( $servicePurple, 30% )
            }
        }
    }
}
.servicePurple-bullets ul li:before {
    border-left-color: $servicePurple !important;
}
.serviceYellow {
    color: $serviceYellow;
    .block-tile {
        background:transparent;
        &:hover{
            background: $serviceYellow;
            color:#fff;
            .icon{
                img {
                    filter: grayscale(1) brightness(800);
                }
            }
            .cta {
                color:lighten( $serviceYellow, 30% )
            }
        }
    }
}
.serviceYellow-bullets ul li:before {
    border-left-color: $serviceYellow !important;
}
.serviceRed {
    color: $serviceRed;
    .block-tile {
        background:transparent;
        &:hover{
            background: $serviceRed;
            color:#fff;
            .icon{
                img {
                    filter: grayscale(1) brightness(800);
                }
            }
            .cta {
                color:lighten( $serviceRed, 30% )
            }
        }
    }
}
.serviceRed-bullets ul li:before {
    border-left-color: $serviceRed !important;
}
.serviceBlue {
    color: $serviceBlue;
    .block-tile {
        background:transparent;
        &:hover{
            background: $serviceBlue;
            color:#fff;
            .icon{
                img {
                    filter: grayscale(1) brightness(800);
                }
            }
            .cta {
                color:lighten( $serviceBlue, 30% )
            }
        }
    }
}
.serviceBlue-bullets ul li:before {
    border-left-color: $serviceBlue !important;
}
.serviceOrange {
    color: $serviceOrange;
    .block-tile {
        background:transparent;
        &:hover{
            background: $serviceOrange;
            color:#fff;
            .icon{
                img {
                    filter: grayscale(1) brightness(800);
                }
            }
            .cta {
                color:lighten( $serviceOrange, 30% )
            }
        }
    }
}
.serviceOrange-bullets ul li:before {
    border-left-color: $serviceOrange !important;
}

.serviceLightBlue {
    color: $serviceLightBlue;
    .block-tile {
        background:transparent;
        &:hover{
            background: $serviceLightBlue;
            color:#fff;
            .icon{
                img {
                    filter: grayscale(1) brightness(800);
                }
            }
            .cta {
                color:lighten( $serviceLightBlue, 30% )
            }
        }
    }
}
.serviceLightBlue-bullets ul li:before {
    border-left-color: $serviceLightBlue !important;
}

.home {
    .banner .tagline-panel.dark h1 {
        @media(max-width: $screen-sm-min) {
        font-size: 26px;
        }       
        margin-top:120px;
        @media(min-width: $screen-sm-min) {
            margin-top:20px;
        }
    }
    .embed-container iframe {
        left: -10%;
        width: 120%;
        @media(min-width: $screen-sm-min) {
            left:0;
            width:100%
        }
    }
}

.latest-media {
    @media (min-width: 1200px){
        .section--latest {
            .latest-title {
                padding-left: 180px;
            }
            .swiper-container-latest {
                padding-left: 10%;
            }
        }
    }
}

// From Customizer

.home .embed-container:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.15);
}

@media (max-width:768px) {
	.home .embed-container iframe{
		left:-25%;
		width:150%;
	}
	.search-page-container {
		margin-top:0;
	}
	.section--latest .event-title {
		top:-5px;
	}
}
.about .double-image-with-cta-container {
	padding-top:0;
	
}

.about .section--head-lvl-2-dark .head-lvl-2-dark-container {
	z-index:100;
	
}

.about .section--map,
.section--success-stories{
	display:none;
	
}

.about .tagline-container p:last-child{
	font-size: 20px;
    max-width: 540px;
}

.about .wpcf7 .wpcf7-form-control-wrap .wpcf7-form-control {
	font-size:20px;
}

.about .wpcf7 .wpcf7-form-control-wrap textarea.wpcf7-form-control {
	height: auto;
    background: #f6f9ff;
    max-height: 200px;
    border: 1px solid #dedede;
    padding: 10px;
    font-size: 19px;
	
}

.about .wpcf7-form .col-xs-12 label{
	border-bottom:none;
}

.team .section--success-stories {
	display:none;
}

#toolbar .new-navigation li a {
	font-size:18px;
}

.event-block img {
	max-height: 210px;
}

.single__content .posted-on-share-bottom {
    border-bottom: none;
	padding: 40px 0 0px !important;
}

.single__content .posted-on {
    border-bottom: 1px solid #ccc;
	padding: 40px 0 40px;
}

.single__content .posted-on .post-nav {
	bottom: 40px;
}

.section--logo-tile .swiper-container-latest--logo-tile .tile-content .sub-icon img {
	margin-top:0px;
}

@media (min-width: 768px) {
	.section--latest .single-event .latest-shadow .image-block .overflow-contain img {
	transform:translate(-20%);
	
}
}

@media (min-width: 1500px) {
.about .simple-content-section {
    padding-right:600px
}
}

@media (max-width: 700px) {
div.wpcf7 .wpcf7-recaptcha iframe {
		bottom: 74px;
    position: relative;
}
}

.wpcf7-form-control-wrap {
	pointer-events: none;
}

.wpcf7-form-control div {
	pointer-events: all;
}

.wpcf7-warning {
color:#000;
}

.banner .gneiss-container.custom-gneiss-cont { z-index: unset; }

.banner .rock-striation {z-index:-1 !important;}

.event-ellipsis {
    width: 100%;
}