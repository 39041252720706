// Glyphicons font path
$icon-font-path: "../fonts/";

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

/*
Define Colors
 */
$brand-primary: #303e48;
$brand-primary2: #748693;
$brand-primary3: #AC9766;

$brand-secondary: #7CA0C5;
$brand-secondary2: #B9B09B;
$brand-secondary3: #7998AC;
$brand-secondary4: #A3BCC4;
$brand-secondary5: #A7ACA0;

$brand-supp: #3EB8AD;
$brand-supp2: #AA63A8;
$brand-supp3: #DBAF40;
$brand-supp4: #CE706A;

$dark-text: #353d47;
/*
 Set Colors
*/
//$brand-highlight: #38a7ca;
$brand-highlight: $brand-secondary;

//Service Colors
$serviceColors:
    #3CBFAE,
    #C018A2,
    #FFDA27,
    #CD3363,
    #3D7DCA,
    #E06B2F
;

//Specific Service colours

$serviceGreen: #3CBFAE;
$servicePurple: #C018A2;
$serviceYellow: #FFDA27;
$serviceRed: #CD3363;
$serviceBlue: #3D7DCA;
$serviceOrange: #E06B2F;
$serviceLightBlue: #98CAEC;

//Fonts
$font-heading: "Proza Libre", sans-serif;
$font-primary: 'Raleway', sans-serif;
$font-secondary: "Open Sans", sans-serif;

//Container Breakpoint
$padding-xs: 15px;
$padding-sm: 40px;
$padding-md: 80px;
$padding-lg: 120px;
$padding-xl: 160px;

//Padding
$padding-med: 60px;