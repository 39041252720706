// CSS FROM CUSTOMIZER

.section--thought-leadership .thought-leadership-container .thought-leadership-cont .thought-leadership-content {
	height:unset
}

.section--thought-leadership .thought-leadership-container .thought-leadership-cont .thought-leadership-content .read-full-link p {
	font-size:8px
}

.section--thought-leadership .thought-leadership-container .thought-leadership-cont .thought-leadership-content h3 {
	padding-bottom: 5px;
    font-size: 14px;
    margin-top: 10px;
}

.section--thought-leadership .thought-leadership-container .thought-leadership-cont {
	margin-top:15px;
	padding-left:0px;
}

.section--thought-leadership .thought-leadership-container-inner {
	padding-left:15px;
}

.section--key-event .key-event-container-info .event-date {
	width:100%;
}

.home .embed-container:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.15);
}

@media (max-width:768px) {
	.home .embed-container iframe{
		left:-25%;
		width:150%;
	}
	.search-page-container {
		margin-top:0;
	}
	.section--latest .event-title {
		top:-5px;
	}
}
.about .double-image-with-cta-container {
	padding-top:0;
	
}

.about .section--head-lvl-2-dark .head-lvl-2-dark-container {
	z-index:100;
	
}

.about .section--map,
.section--success-stories{
	display:none;
	
}

.about .tagline-container p:last-child{
	font-size: 20px;
    max-width: 540px;
}

.about .wpcf7 .wpcf7-form-control-wrap .wpcf7-form-control {
	font-size:20px;
}

.about .wpcf7 .wpcf7-form-control-wrap textarea.wpcf7-form-control {
	height: auto;
    background: #f6f9ff;
    max-height: 200px;
    border: 1px solid #dedede;
    padding: 10px;
    font-size: 19px;
	
}

.about .wpcf7-form .col-xs-12 label{
	border-bottom:none;
}

.team .section--success-stories {
	display:none;
}

#toolbar .new-navigation li a {
	font-size:18px;
}

.event-block img {
	max-height: 210px;
}

.single__content .posted-on-share-bottom {
    border-bottom: none;
		padding: 40px 0 0px;
}

.single__content .posted-on {
    border-bottom: 1px solid #ccc;
		padding: 40px 0 40px;
}

.single__content .posted-on .post-nav {
	  bottom: 40px;
}

.section--logo-tile .swiper-container-latest--logo-tile .tile-content .sub-icon img {
	margin-top:0px;
}

@media (min-width: 768px) {
	.section--latest .single-event .latest-shadow .image-block .overflow-contain img {
	transform:translate(-7%);
	
}
}

@media (min-width: 1200px) {
	.single .banner .tagline-panel.light 		h1 {
		line-height:1.1;
		font-size:48px
	}
}


@media (min-width: 1500px) {
.about .simple-content-section {
    padding-right:600px
}
}

@media (max-width: 700px) {
div.wpcf7 .wpcf7-recaptcha iframe {
		bottom: 74px;
    position: relative;
}
}

.wpcf7-form-control-wrap {
	pointer-events: none;
}

.wpcf7-form-control div {
	pointer-events: all;
}

.wpcf7-warning {
color:#000;
}

.banner .gneiss-container.custom-gneiss-cont { z-index: unset; }

.banner .rock-striation {z-index:-1 !important;}


@media (max-height:830px) {
	.banner .tagline-panel.dark {
		bottom:60px;
		
	}
	
}

.single .relatedImage {
	background-repeat: no-repeat;
	background-size: contain;
}

.single .side-images-bottom img{
	border-radius:5px;
	box-shadow:0 4px 17px rgba(0,0,0,.12);
	padding: 5px 10px;
    background: #fff;
    min-height: 55px;
    max-height: 200px;
    object-fit: contain;
}

