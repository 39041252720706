// Search form
.search-form {
    @extend .form-inline;

    display: inline;

    label, .search-field {
        width: 360px;

        @media (max-width: $screen-xs-max) {
            width: calc(100% - 50px);
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            width: 230px;
        }
    }

    .fa-search {
        position: absolute;
        font-size: 2em;
        height: 60px;
        margin-left: 15px;
        color: rgba(255, 255, 255, 0.7);

        @media(max-width: $screen-sm-max) {
            height: 40px;
            font-size: 1.6em;
        }
    }

    .search-field {
        width: 100%;
        margin-right: 15px;
        height: 60px;
        background-color: rgba(0, 0, 0, 0.6);
        border: none;
        border-radius: 0px;
        font-size: 1.3em;
        padding-left: 10px;
        color: rgba(255, 255, 255, 0.7);
        text-transform: uppercase;
        font-weight: 700;

        @media(max-width: $screen-sm-max) {
            height: 40px;
            font-size: 12px;
        }
        @media(min-width: $screen-lg-min) {
            padding-left: 50px;
        }
    }

    .search-submit {
        display: none;
    }
}

.search-form label {
    font-weight: normal;

    @extend .form-group;
}

.search-form .search-field {
    @extend .form-control;
}

.search-form .search-submit {
    @extend .btn;

    @extend .btn-default;
}

hr {
    text-align: left;
    margin-left: 0px;
    width: 150px;
    border-top: 5px solid #7CA0C5;
    margin-top: 20px;
}

//CF7
.wpcf7{

    @extend .form-inline;

    .wpcf7-form-control.wpcf7-recaptcha{
        background-color: transparent;
        padding: 0 0 ;
        height: 80px !important;
        padding-bottom: 75px;
        @media(max-width: $screen-xs-max) {
            margin-top: 90px;
        }
    }

    .light {
        .wpcf7-form-control {
            color: #333;
            background-color: #fff;

            &:focus {
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 30px rgba(102, 175, 233, 0.8);
            }
        }
    }

    .dark {
        .wpcf7-form-control {
            color: #fff;
            background-color: rgba(0, 0, 0, 0.35);

            &:focus {
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.8);
            }
        }
    }

    label {
        font-family: $font-primary;
        font-weight: 500;
        width: 100%;
        color: #C0C0C0;
        background-color: rgba(255, 255, 255, 0.7);
        font-size: 22px;
        text-transform: uppercase;
        border-bottom: 1px solid #C0C0C0;
        margin-bottom: 75px;
    }

    .right-form-side {
        iframe{
            max-width:100% !important;
            width: 100% !important;
        }
        @media (min-width: 992px){
            padding-left: 70px;
        }
        label {
            border: 1px solid #C0C0C0;
            padding: 20px 40px;
            margin-bottom: 10px;
            @media (min-width: 750px){
                height: 430px;
            }
        }
        .wpcf7-submit {
            display: block;
            margin: 0 auto;
            color: white;
            background-color: #7CA0C5;
            border-radius: 10px;
            width: 250px;
        }
        textarea {
            font-size: 36px;
        }
    }

    .left-form-side {
        @media (min-width: 750px){
            padding-right: 70px;
        }
        div {
            padding-left: 0px;
        }
        &:after {
            content: "";
            border-right: 2px solid #C0C0C0;
            height: 200%;
            position: absolute;
            right: -1px;
            top: -248px;
        }
    }

    .wpcf7-form-control-wrap {
        width: 100%;

        .wpcf7-form-control {
            @extend .form-control;
            font-family: $font-primary;
            font-weight: 100;
            font-size: 20px;
            color: #101010;
            height: 60px;
            width: inherit;
            border: none;
            border-radius: 0px;
            padding-left: 0px;
            border-style: none;
            box-shadow: none;
            &::placeholder {
                color: #101010;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }


}
.form-content{
    font-size:18px;
    .form-col-one{
        @media (max-width:768px) {
            margin-bottom: 15px;
        }
    }
}

.email-output{
    margin-bottom:20px;
    a{
        color:white;
    }
}

.address-output{
    @media (max-width:1200px){
        font-size:16px;
    }
}
div.wpcf7 .wpcf7-recaptcha iframe {
    display: block;
    position: absolute;
}
.wpcf7-validation-errors {
    color: black;
}
