footer {
    background-color: #343E47;
    position: relative;
    z-index: 1010;
    overflow:hidden;
    @extend .cover-image;
    color: #fff;

    .gneiss-container {
        padding-top: $padding-xs;

        @media (min-width: $screen-sm-min) {
            padding-top: $padding-sm;
        }

        @media (min-width: $screen-md-min) {
            padding-top: 40px;
        }
    }

    &.gneiss-footer {
        .gneiss-container {
            padding: 40px 30px 0px;
            @media (min-width: 800px){
                padding: 40px 80px 0px;
            }
        }

        @media (max-width: 1000px){
            text-align: center;
        }

        .footer-left {
            padding: 10px;
        }

        @media (min-width: 900px){
            .footer-left-bottom {
                position: relative;
                top: 86px;
            }
        }

        .footer-right {
            a {
                color:#fff;
                font-size: 16px;
                margin-bottom: 10px;
                transition:0.3s all ease;
                @media(min-width: $screen-md-min) {
                   font-size: 15px;
                }
                @media(min-width: $screen-lg-min){
                   font-size: 16px;
                }
                &:hover {
                    color:#7CA0C5;
                    text-decoration:none;
                }
            }

            .footer-right-top {

                @media(min-width: $screen-lg-min){
                    display: flex;
                    justify-content: space-around;
                }

                .col-md-4 {
                    margin-top: 45px;
                    @media(min-width: 992px){
                        margin-top: 0px;
                    }
                }

                p {
                    display: inline-block;
                    font-size: 17px;
                }

                .footer-right-blue {
                    width: 56%;
                    margin: 0 auto;
                    border-bottom: 3px solid #7CA0C5;
                    position: relative;
                    display: inline-block;
                    @media(min-width: $screen-md-min) {
                        width: 75%;
                    }
                    @media(min-width: $screen-lg-min){
                        width: 50%;
                    }
                    @media (min-width: 1500px){
                        width: 60%;
                        margin: unset;
                        display: inline-block;
                        margin-left: 20px
                    }
                    &.email {
                        @media(min-width: $screen-md-min) {
                        width: 89%;
                        }
                        @media(min-width: $screen-lg-min){
                            width: 50%;
                        }
                        @media (min-width: 1500px){
                            width: 60%;
                        }
                    }
                }

                .footer-text-small {
                    padding-left: 0px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    span {
                            color:$serviceLightBlue;
                            display: inline-block;
                            min-height: 40px;
                        }
                    @media (min-width: 768px) {
                        margin-top: 10px;
                        margin-bottom: 0px;
                        padding-left: 40px;
                    }

                    p {
                        font-size: 16px;
                        margin-bottom: 10px;
                        min-width: 85px;
                        &:nth-last-child(1){
                            font-weight: bold;
                        }
                        @media (min-width: 1178px) {
                            max-width: 140px;
                        }
                        @media (min-width: 1200px) {
                            max-width: none;
                        }
                        @media (min-width: 1507px) {
                            min-width: 100px;
                        }
                        @media (min-width: 1868px) {
                            max-width: 135px;
                        }
                        @media (min-width: 2000px) {
                            max-width: none;
                        }
                    }
                    .svg-inline--fa {
                        position: relative;
                        left: -7px;
                        top: 4px;
                        font-size: 28px;
                        @media(min-width: $screen-md-min){
                            position: absolute;
                            left: 0px;
                            top: -4px;
                        }
                    }
                    &.phone {
                        margin-top: 20px;
                        @media(min-width: $screen-md-min) {
                            margin-bottom: 50px;
                        }
                        @media(min-width: $screen-lg-min){
                            margin-bottom: 0px;
                        }
                    }
                }
            }

            .footer-right-bottom {
                margin-top: 10px;
                padding-top: 60px;
                @media (min-width: 600px) {
                    margin-top: 40px;
                }
                p {
                    font-size: 12px;
                    margin-left: 15px;
                    display: inline-block;
                }
                .footer-text-darkblue {
                    color: #7998AC;
                }
            }
        }

        .footer-logo-container {
            margin-top: 20px;
            @media (min-width: 600px){
                margin-top: 0px;
            }
            img {
                width: 200px;
            }
            .footer__tagline {
                margin-top: 30px;
                font-family: $font-heading;
                font-size: 13px;
            }
        }

        .footer__blue2 {
        display: inline-block;
        height: 60px;
        font-size: 12px;
        margin: 0;
        line-height: 30px;
        .blue2-footer-link{
            width: auto;
            height: 20px;
        }
        .blue2-footer-logo {
            height: 20px;
            width: auto;
            vertical-align: top;
        }

        @media (min-width: $screen-md-min) {
            float: right;
        }
        }

        @media (max-width: 900px) {
            div {
                padding: 0px;
            }
        }
    }


    .footer__legal{
        p {
            font-size: 10px;
            text-transform: uppercase;
        }
    }

    .footer__social {
        padding: 15px 0;
        text-align: center;

        a {
            margin: 0 5px;
            color: #fff;
        }
    }

    label {
        text-align: left !important;
    }
    &:before, &:after {
        content:'';
        position:absolute;
        top:-40px;
        width:100%;
        height:100vh;
        z-index:-1;
        background-repeat:no-repeat;
    }
    &:before {
        left:-30px;
        background-image:url(../images/footer-left.svg);

    }
    &:after {
        right:-920px;
        background-image:url(../images/footer-right.svg);
    }
}
.address-section{
    text-align:center;
    p{
        font-size:14px;
    }
}
.copyright{
    text-align:left;
    font-size:14px;
    @media (max-width:768px){
        text-align:center;
    }
}
.footer__policies{
    text-align: center;
    @media (min-width: 900px){
        text-align: left;
    }

    padding-left:0;
    li{
        text-transform:uppercase;
        font-size:10px;
        border-right: 1px solid white;
        padding-right: 12px;
        &:nth-child(3) {
            border-right: unset;
        }
        a{
            color:white;
            font-weight:600;
        }
    }
}

