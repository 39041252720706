

.section-case-study {
  @extend .container-fluid;

  @media (min-width: 450px) {
    padding: 0 2.5%;
  }
  @media (min-width: 1500px) {
    padding: 0 12.5%;
  }

  .case-study-container {
    display: block;
  }
}

.related-case-study{
  position:relative;
  max-width:100%;
  margin-bottom:50px;
  z-index:100;
  height:300px;
  @media(max-width:768px){
    height:auto;
  }

  h2{
    margin-top:0;
    font-size:24px;
    margin-bottom:25px;
  }

  .content-section{
    padding: 25px;
    background:white;
    height:100%;
    .excerpt{
      font-size:20px;
      color:#333;
    }
    .cta{
      margin-top:25px;
      text-transform:uppercase;
      font-size:20px;
      svg{
        transition:0.3s;
      }
    }
  }
  a{
    box-shadow: 0 4px 17px rgba(0,0,0,.12);
    overflow: hidden;
    display:block;
    width:100%;
    height:100%;
    &:hover{
      svg{
        margin-left:10px;
      }
      img{
        transform:scale(1.1);
      }
    }
  }
  .image-section{
    height:100%;
    position:relative;
    overflow: hidden;

    @media(max-width:768px){
      height:250px;
    }

    img{
      max-width:100%;
      height:100%;
      width:100%;
      object-fit: cover;
      transition:0.3s;
    }
  }
}
