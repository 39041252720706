.btn-primary {
    font-family: $font-heading;
    color: #fff;
    background-color: $brand-highlight;
    font-weight: 500;
    padding: 12px 45px;
    border-radius: 40px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    border: none;
    display: inline-block;
    position: relative;
    overflow: hidden;


    &.transparent {
        background-color: rgba($brand-highlight, 0.8);
    }

    &:hover {
        text-decoration: none;
        color: #fff;
        background-color: #23527C;
    }

    @media(max-width: $screen-xs-max) {
        padding: 12px;
    }
    @media(min-width: $screen-md-min) {
        font-size: 16px;
    }
}

.btn-secondary {
    @extend .btn-primary;
    background-color: #e7e7e7;
    color: #000;

    @media(min-width:$screen-md-min) and (max-width: $screen-md-max){
        width: 85%;
        margin: 0 auto;
    }
}

.btn-rect {
    @extend .cover-image;
    background-image: url(../images/button-wash.jpg);
    padding: 75px 50px;
    border-radius: 0;
    border: 1px solid gray;
    color: #353D47;
    overflow: hidden;
    width: 300px;
    display: inline-block;
    position: relative;
    font-family: $font-heading;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;
    width: 100%;
    @media(min-width: $screen-sm-min){
        margin: 0 15px;
        width: 275px;
    }
    &:after {
        position: absolute;
        width: 700px;
        height: 250px;
        background: rgba(255, 255, 255, 0.2);
        content: '';
        -webkit-transition: -webkit-transform 0.4s;
        transition: transform 0.4s;
        -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
        transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
    }

    &:hover {
        text-decoration: none;
        transition: ease all 0.4s;
        background: #b6b6b6;
        &:after {
            -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 65%, 0);
            transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 65%, 0);
        }
    }
}

.btn-toggle {
    height: 60px;
    width: 60px;
    border: none;
    color: #fff;
    background-color: $brand-highlight;
    padding: 0;
    .svg-inline--fa{
        overflow: hidden;
    }
    @media(max-width: $screen-sm-max) {
        height: 40px;
        width: 40px;
        float: right;
    }
}

