.single__content {
    padding: 5px 15px 0 0;
    background: #fff;
    
    @media(max-width:$screen-sm-max){
        overflow: hidden;
    }
    header{
        margin-bottom: 35px;
        @media(min-width:1500px){
            margin-bottom: 0px;
        }
    }
    li{
        font-size: 18px;
    }
    .posted-on {
        padding: 40px 0px 40px;
        border-bottom: 1px solid #ccc;
        &-date{
            display: inline-block;
            float: left;
            p {
                font-size: 12px;
            }
        }
        &-share {
            display: flex;
            float: right;
            &-bottom {
                display: flex;
                border-bottom: none;
                padding: 40px 0px 0px;
            }
        }
        .facebook {
            color: #3b5998;
        }
        .twitter {
            color: #00aced;
        }
        .linkedIn {
            color: #007bb6;
        }
        .post-nav {
            flex: auto;
            position: relative;
            bottom: 40px;
        }
    }
}

time {
    font-family: $font-heading;
    font-size: 24px;
}

.single .relatedImage {
	background-repeat: no-repeat;
	background-size: contain;
}