/**
 * Team Template
 */

.spacer {
  min-height: 65px;
}

#role {
    text-align: left;
    @media(min-width: $screen-sm-min) {
        font-size: 30px;
    }
    @media(min-width: $screen-md-min) {
        font-size: 38px;
    }
}

.profile {
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &--large {
        max-height: 500px;

        img {

            height: auto;
            width: 100%;
            object-fit: cover;
        }

        @media(max-width: $screen-xs-max) {
            img {
            }
        }
    }
}

.info-panel {
    height: 100%;
    padding: 60px;
    position: relative;
    transition: ease transform 0.3s;
    z-index: 1000;

    &:hover {
        z-index: 1100;
        cursor: pointer;
        @media(max-width: $screen-sm-max) {
            transform: scale(1.05);
        }
    }

    @media (min-width: $screen-lg-min) {
        padding: 70px 60px;
    }

    text-align: center;
    color: #fff;
    background: $brand-highlight;

    h2 {
        margin-top: 0;

        color: #fff;
        text-transform: uppercase;
    }

    @media(min-width: $screen-md-min) and (max-width: 1600px) {
        h2 {
            font-size: 30px;
        }
    }

    hr {
        border-color: #fff;
    }

    h3 {
        color: #fff;
        text-transform: uppercase;
    }
}

.team-cta {
    padding: 0;

    .info-panel--large {
        @extend .cover-image;
        background-image: url(../images/button-wash.jpg);

        a {
            color: #353D47;
        }
    }
}

.info-panel--large {
    @extend .info-panel;
    text-align: center;
    background-color: #9e9e9e;

    a {
        color: #fff;
        font-family: $font-heading;
        font-size: 24px;
        text-transform: uppercase;
    }

    @media(min-width: $screen-sm-min) {
        padding: 180px 0;
    }
    @media(min-width: $screen-sm-min) {
        padding: 220px 0;
    }

    p {
        padding: 0 40px;
    }

    &:hover {
        cursor: default;
        transform: none;
    }

}

.team-description {
    display: none;
}

.logo-wrapper {
    background: #fff;
    padding: 50px;
    overflow: auto;
}

.logo-wrapper--narrow {
    @extend .logo-wrapper;
    padding: 25px;
}


.advisor-logo {
    position: relative;
    top: 0;
    transition: all ease 0.1s;
    padding-bottom: 15px;

    img {
        max-height: 150px;
        transition: all ease 0.1s;
        filter: grayscale(1);
    }

    &:hover {
        box-shadow: 0 3px 60px rgba(37,37,37,.25);
        z-index: 100;
        a img {
            filter: unset;
        }
    }
}

/**
 * Services Template
 */

$scroll-nav-width-xs: 50px;
$scroll-nav-width-sm: 120px;
$scroll-nav-width-md: 150px;
$scroll-nav-width-lg: 200px;

@mixin serviceColor($n) {
    #service#{$n} {
        strong {
            color: nth($serviceColors, $n);
        }

        ul {
            padding-left: 15px;

            li {
                list-style: none;
                font-size: 18px;
                padding-left: 19px;
                position: relative;
                margin-bottom: 5px;

                &:before {
                    content: "\2022";
                    font-size: 18px;
                    padding-right: 1.1225em;
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: nth($serviceColors, $n);
                    list-style-position: inside;
                }
            }
        }
    }
}

.services {
    a.btn-sectors {
        margin: $grid-gutter-width auto (10px + $grid-gutter-width);

        @media(min-width: $screen-sm-min) {
            margin: $grid-gutter-width auto (40px + $grid-gutter-width);
        }

        @media(min-width: $screen-md-min) {
            margin: $grid-gutter-width auto (80px + $grid-gutter-width);
        }
    }
}

.services-container {

    @extend .container-fluid;
    display: inline-block;
    position: relative;

    @for $i from 1 through 6 {
        @include serviceColor($i);
    }

    width: calc(100% - 50px);
    @media(min-width: $screen-sm-min) {
        width: calc(100% - 120px);
    }
    @media(min-width: $screen-md-min) {
        //width: calc(100% - 150px);
    }
    @media(min-width: 1200px) {
        width: calc(100% - 200px);
    }

    .split {
        padding: 15px;
        background-color: #dcdcdc;

        @media(min-width: $screen-sm-min) {
            min-height: 300px;
            padding: 30px;
        }
        @media(min-width: $screen-md-min) {
            min-height: 400px;
            padding: 50px;
        }

        &.large {
            @extend .cover-image;
            background-color: $brand-primary;
            @media(max-width: $screen-xs-max) {
                height: 250px;
            }
            @media(max-width: $screen-sm-max) {
                border-bottom: 1px solid #7d7d7d;
            }
        }

        h2 {
            text-transform: uppercase;
        }

        p {
            margin-bottom: 20px;
        }

        ul {
            font-size: 16px;
        }

        a {
            margin-top: 5px;
        }

        .services__logo {
            height: 150px;
            width: 150px;
            float: right;
            margin: 5px;
        }
    }
}

.scroll__container {
    padding-top: 30px;
    height: 500px;
    top: 0px;
    // height: 100vh;
    float: right;
    background: #fff;

    @media(max-width: $screen-xs-max) {
        width: $scroll-nav-width-xs;
    }
    @media(min-width: $screen-sm-min) {
        width: $scroll-nav-width-sm;
    }
    @media(min-width: $screen-md-min) {
        //width: $scroll-nav-width-md;
    }
    @media(min-width: 1200px) {
        width: $scroll-nav-width-lg;
    }

    li {
        margin-bottom: 10px;

        &.active, &:hover {
            a {
                @for $i from 1 through 6 {
                    &#service#{$i} {
                        background-color: nth($serviceColors, $i);;
                    }
                }
            }
        }

        a {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #dcdcdc;
            padding: 0;
            position: relative;
            left: calc(50% - 7px);

            span {
                display: none;
            }
        }
    }

    ul {
        &:before {
            position: absolute;
            //z-index: -1;
            margin-top: -30px;
            height: 100vh;
            border-left: 4px dotted #000;
            @media(max-width: $screen-xs-max) {
                right: 23px;
            }
            @media(min-width: $screen-sm-min) {
                right: 58px;
            }
            @media(min-width: $screen-md-min) {
                //right: 74px;
            }
            @media(min-width: 1200px) {
                right: 98px;
            }
        }
    }
}

/**
 * Media Template
 */

body {
    &.latest-media, &.sectors {
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
    }
}

.media__tabs {
    text-align: center;
    width: 90%;
    margin: 30px auto;

    a {
        font-weight: 500;
        font-family: $font-heading;
        text-transform: uppercase;
        margin: 0 8px;
        text-decoration: none;
        color: #fff;
        font-size: 18px;

        &:hover {
            color: $brand-highlight;
        }

        &.active {
            color: $brand-highlight;
        }

        @media(max-width: $screen-xs-max) {
            display: block;
            margin: 8px 0px;
        }
        @media(min-width: $screen-md-min) {
            font-size: 25px;
        }
    }
}

.media-holder {
    background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0.6),
                    transparent
    );
}

.media__latest {
    background-color: transparent;
    padding-top: 15px;

    @media(min-width: $screen-sm-min) {
        //padding-top: 40px;

        .tab-content {
            #events, #insights {
                .latest-panel--small {
                    margin-top: 30px;
                }
            }
        }
    }
    @media(min-width: $screen-md-min) {
        padding-top: 1px;
        .tab-content {
            #events, #insights {
                .latest-panel--small {
                    margin-top: 0;
                }
            }
        }
    }

    @media(min-width: 1450px) {
        .tab-content {
            .gneiss-container {
                max-width: 1920px;
            }
        }
    }

    .gneiss-container {
        max-width: 1920px;
    }

    .latest-links {
        ul {
            a {
                color: $brand-highlight;
            }
        }
    }

    &.media-events {
        @extend .cover-image;
        background-image: url(../images/background-wash-new.jpg);

        @media(max-width: $screen-sm-max) {
            .latest-panel {
                margin-top: 15px;
            }
        }
    }

    &.media-articles {
        h2 {
            text-align: center;
            text-transform: uppercase;
        }
    }

    &.media-articles, &.media-events {
        @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            .latest-panel--small {
                margin-bottom: 25px;
            }
        }
    }

    .article-container {
        @extend .container;

        h2 {
            text-align: center;
            text-transform: uppercase;
        }
    }

    &.media-market {
        background-size: contain;
        background-position: 50%;
        //background-image: url('../images/background-wash.jpg');
        padding: 15px 10px 10px 10px;
        height: 100%;
        @media(min-width: $screen-sm-min) {
            padding: 20px 0;
        }
        @media(min-width: $screen-md-min) {
            padding: 20px 0 60px 0;
        }
        background-color: transparent;

        h2 {
            margin: 20px 0;
            color: #fff;
            font-size: 18px;

            @media(min-width: $screen-md-min) {
                font-size: 25px;
            }
        }

        .latest-panel--small {
            border: 1px solid #fff;
            transition: all 0.3s ease;
            height: 350px;
            position: relative;
            float: left;
            margin-top: 0;
            width: 100%;
            margin-bottom: 10px;
            box-shadow: inset 0 0 2px #fff;

            .cover-link__url {
                width: 100%;
                height: 100%;
            }

            @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                border: 2px solid #fff;
                margin-bottom: 0;
                width: 50%;
                height: 200px;
                &:nth-child(1) {
                    width: 100%;
                    height: 400px;
                }
                &:nth-child(2), &:nth-child(3) {
                    border: 2px solid #fff;
                    height: 400px;
                }
            }

            @media(min-width: $screen-md-min) {
                width: 33.33333%;
                margin-bottom: 0;
                height: 200px;
                &:nth-child(1) {
                    width: 66.6666667%;
                    height: 400px;
                }
                &:nth-child(2) {
                    height: 400px;
                }
                &:nth-child(4) {
                    height: 400px;
                    width: 66.6666667%;
                }
                &:nth-child(5) {
                    margin-top: -200px;
                    height: 400px;
                }
                &:nth-child(6) {
                    height: 400px;
                }
                &:nth-child(9) {
                    margin-top: -200px;
                }
            }
        }

        .content-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            min-height: 40px;
            background: rgba(0, 0, 0, 0.6);
            padding: 8px 18px;
            pointer-events: none;

            h3 {
                color: #fff;
                font-size: 17px;
                margin: 13px 0;
            }
        }

        hr {
            margin: 20px auto;
        }

        a {
            margin-bottom: 10px;
        }

        .latest-links {
            ul {
                a {
                    color: #000;
                }
            }
        }
    }
}

//Search Page
.search-page-container {
    margin-top: -400px;
}
.search__content {
    padding: 0px 0px 50px;
    z-index: 100;

    .latest-panel {
        margin-bottom: 15px;
    }

    .latest-panel--small {
        max-width: 444px;
        display: block;
        margin: 0 auto;
    }
    .event-block {
        max-width: 444px;
        display: block;
        margin: 0 auto;
    }
    .nav-links {
        display: flex;
        justify-content: center;
        .nav-previous,
        .nav-next {
            a {
                width: 150px;
                height: 40px;
                border: 1px solid #7CA0C5;
                border-radius: 5px;
                margin-left: 10px;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.single-post,
.single,
.single-events {
    .gneiss-container {
        z-index: 320;
        .side-images {
            display:none;
            @media (min-width: 800px) {
                padding: 0px 25px;
            }
            @media (min-width: 1500px) {
                display:block;
                margin-top: 200px;
                padding: 0px 115px;
            }
            .side-images-top,
            .side-images-bottom {
                img {
                    width: 100%;
                }
            }
            .side-images-top {
                @media (min-width: 1500px) {
                    width: 270px;
                }
            }
            .side-images-bottom {
                padding: 0px 30px;
                @media (min-width: 1500px) {
                    width: 270px;
                    min-height: 100px;
                }
            }
        }
        &-left {
            z-index: 300;
        }
        @media (min-width: 800px) {
            padding: 0 50px;
        }
        @media (min-width: 1200px) {
            padding: 0 100px;
        }
        @media (min-width: 1500px) {
            padding: 0 150px;
        }
        @media (min-width: 1800px) {
            padding: 0 250px;
        }
    }
    .banner {
        .gneiss-container {
           @media (min-width: 800px) {
                padding: 0 100px;
                padding-top:20px;
            }
        }
        .tagline-panel h1 {
            @media (max-width: 1200px) and (min-width: 992px) {
                font-size: 22px;
            }
        }
    }
    .gneiss-container-right {
        z-index: 310;
        width: 100%;
        top: 0;
        padding: 0 20px;
        pointer-events: none;
        @media (min-width: 450px) {
            padding: 0 60px;
        }
        @media (min-width: 550px) {
            padding: 0 100px;
        }
        @media (min-width: 650px) {
            padding: 0 120px;
        }
        @media (min-width: 750px) {
            padding: 0 60px;
        }
        @media (min-width: 992px) {
            position: absolute;
        }
    }
    .relatedArticle {
        padding: 20px;
        border-top: 1px solid #7d7d7d;
        border-bottom: 1px solid #7d7d7d;
        display: inline-block;
        width: 100%;
        p {
            font-size: 16px;
            margin-bottom: 0px;
        }
        h2 {
            margin-top: 2px;
            margin-bottom: 25px;
            font-size: 22px;
        }
        h3 {
            margin-bottom: 25px;
        }
        a {
            border: 1px solid #7d7d7d;
            border-radius: 5px;
            padding: 15px;
            float: right;
            color: #000;
        }
    }
    .relatedImage {
        height: 220px;
        background-size: cover;
    }
    .relatedPerson {
        white-space: pre-line;
    }
    .entry-content {
        img {
            // float: right;
            margin-left: 40px;
            width: 50%;
            margin-right: -120px;
            margin-top: 25px;

            &:before {
                border-color: transparent #444 transparent transparent;
                border-style: solid;
                border-width: 0 21px 14px 0;
                height: 0;
                left: 0;
                width: 0;
                top: 49px;
                content: "";
                position: absolute;
                z-index: 1;
            }
        }
    }
}

.index-wrapper {
    @media(min-width: $screen-md-min) {
        border-right: 1px solid gray;
    }
    &.search {
        .search-results-cont {
            height: unset;
            @media (min-width: 768px) {
                height: 460px;
            }
            z-index: 400;
        }
        article {
            margin-top: 20px;
            margin-bottom: 20px;
            .event-block {
                padding: 15px;
                border: unset;
                box-shadow: 0px 0px 30px -15px rgba(0,0,0,0.75);
                .content-section {
                    padding: 10px 0px 0px 0px;
                }
                a {
                    color: black;
                }
            }
        }
    }
}

//Sectors
.sectors-content {
    @media(min-width: $screen-sm-min) {
        margin-top: -75px;
        padding-bottom: 40px;
    }

    @media(min-width: $screen-md-min) {
        margin-top: -150px;
        padding-bottom: 80px;
    }

    a.btn {
        margin: $grid-gutter-width auto;
    }

    .sector-panel {
        @extend .cover-image;
        width: 100%;

        .block {
            border: 1px solid #fff;
            overflow: hidden;
            position: relative;

            img {
                width: 100%;
                object-fit: cover;
                height: auto;
            }

            @media(min-width: $screen-md-min) {
                height: 400px;
                width: 50%;
                float: left;
                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
                &:hover {
                    cursor: pointer;

                    .text {
                        top: 0;
                    }
                }
            }

            .text {
                padding: 15px;
                background: rgba(0, 0, 0, 0.4);
                transition: top 0.6s ease;

                p {
                    font-size: 16px;
                }

                ul {
                    margin: 0;
                    padding-left:20px;
                }
                @media(min-width: $screen-md-min) {
                    position: absolute;
                    top: 300px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    ul {
                        li {
                            width: 50%;
                            float: left;
                        }
                    }
                }

                @media (min-width: $screen-lg-min) {
                    padding: 15px 50px;
                }

            }

            h3 {
                margin: 10px 0;
                text-align: center;
                font-size: 22px;
                text-transform: uppercase;

                @media (min-width: $screen-sm-min) {
                    margin-bottom: 20px;
                }
            }

            hr {
                margin: 25px auto;
                border-color: #fff;
            }
        }
    }
}

.custom-event-type{
    position: absolute;
    bottom: 30%;
    left: 20px;
    margin-bottom: 0;
    font-size:13px;

    &.home-type{
        background:$brand-secondary;
    }

    &.past-custom{
        background-color:#748693;
    }

    @media (max-width:375px){
        position:relative;
        bottom:auto;
        right:auto;
        margin-top:10px;
    }

}

@media(min-width:1500px) {
    .custom-col-3 {
        width: 25% !important;
        float: left !important;
        position: relative !important;
        min-height: 1px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.event-block {
    background:white;
    border: 1px solid #dcdcdc;

    img{
        transition:0.5s;
        &:hover{
            transform: scale(1.05);
        }
    }

    .content-section{
        padding:20px;
        h3{
            margin-top: 0;
            font-size: 14px;
            font-weight: 800;
            margin-bottom: 5px;
            padding-bottom:25px;
        }
        .date{
            font-size: 14px;
            font-family: "Open Sans",sans-serif;
            margin: 0 0 5px 0;
        }
    }
}

.latest-col {
    padding: 15px;
    background-color: white;
    max-width: 440px;

    .latest-shadow {
        box-shadow: 0 4px 17px rgba(0,0,0,.12);
        padding: 20px;
    }

    img {
        transition: 0.5s;
        @media(max-width:992px) {
            width: 100%;
        }
        &:hover{
            transform: scale(1.05);
        }
    }

    &.news-col{
        .custom-event-type{
            opacity:0;
        }
    }

    .latest-data-item{
        background:white;
    }
    .custom-event-type{
        background:$brand-secondary;
        font-size: 13px;
        margin-top: -50px;
        @media(max-width:1200px){
            position:absolute;
            left:auto;
        }
        @media(max-width:1540px){
            position: absolute;
        }
    }
    .event-col {
        .content-block {
            padding-top:20px;
        }
    }
    .content-block{
        padding-top:20px;
        padding-bottom:0px;
        font-size: 14px;
        font-family: Raleway,sans-serif;
        margin: 0;
        text-align: left;
        h3{
            margin-top: 0;
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 700;
            font-family: Raleway,sans-serif;
        }
        .date{
            font-size: 14px;
            font-family: Raleway,sans-serif;
            margin: 0 0 5px;
        }
    }
}

.single-sector-page {
    padding: 40px 20px;
    @media(min-width: 600px){
        padding: 40px 60px;
    }
    @media(min-width: 650px){
        padding: 40px 80px;
    }
    @media(min-width: 700px){
        padding: 40px 100px;
    }
    @media(min-width: 1200px){
        padding: 55px 840px 55px 275px;
        margin-top: -460px;
        margin-bottom: 200px;
    }
    .sector-page-content {
        position: relative;
        z-index: 410;
        background-color: white;
        padding: 20px 20px;
        @media(min-width: 600px){
            padding: 20px 40px;
        }
        @media(min-width: 700px){
            padding: 20px 40px;
        }
        @media(min-width: 1200px){
            padding: 40px 40px;
        }
        h2 {
            font-size: 40px;
            font-weight: 700;
            margin-top: 5px;
            margin-bottom: 25px;
        }
    }
}

.bottom-box-shadow {
    box-shadow: 0px 20px 25px -15px rgba(133,133,133,0.2);
}

.scroll-header {
    position: sticky;
    top: 0;
    z-index: 1024;
    width: 100%;
    background-color: rgba(255,255,255,0);
    padding-top: 0;
    @media(min-width: $screen-lg-min){
        z-index: 310;
    }
    &.fixed {
        @media(min-width: 1500px){
            padding-top: 75px;
            margin-top: -75px;
        }
    }
    .progress-container {
        width: 100%;
        height: 8px;
        background: #eaeaea;
        @media(min-width: 768px){
            background: #ccc;
        }
        .progress-bar {
            height: 8px;
            background: #7CA0C5;
            width: 0%;
        }
    }
}

.single-service-page {
    background-size: cover;
    position: relative;
    display: inline-block;
    padding: 40px 20px;

    @media(min-width: 600px){
        padding: 40px 60px;
    }
    @media(min-width: 650px){
        padding: 40px 80px;
    }
    @media(min-width: 700px){
        padding: 40px;
    }
    @media(min-width: 1200px){
        padding: 40px 0px 40px 100px;
    }
    @media(min-width: 1500px){
        padding: 55px 0px 55px 240px;
    }
    .service-page-content {
        z-index: 350;
        position: relative;
        background-color: rgba(255,255,255,0.7);
        padding: 20px 0px;
        @media(min-width: 600px){
            padding: 20px 0px;
        }
        @media(min-width: 650px){
            padding: 20px 0px;
        }
        @media(min-width: 700px){
            padding: 20px 0px;
        }
        @media(min-width: 1200px){
            padding: 20px 0px;
            margin-bottom: 6px;
        }
        @media(min-width: 1500px){
            padding: 40px 40px 40px 10px;
        }
        @media(min-width: 1800px){
            padding: 40px 40px 40px 10px;
        }
        &-title {
            display: inline-block;
            width:100%;
            &-text {
                display: inline-block;
            }
            &-image {
                display: block;
                width: 120px;
                margin: 0 auto 10px;
                @media(max-width:768px) {
                    position: unset !important;
                }
                @media(min-width:768px) {
                    position: absolute;
                    right: 20px;
                    top: 0px;
                }
                @media(min-width:1500px) {
                    right: 30px;
                    top: 5px;
                }
                img {
                    width: 120px;
                }
            }
        }
        .key-service {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 5px;
        }
        h2 {
            font-size: 40px;
            font-weight: 700;
            margin-top: 5px;
            margin-bottom: 25px;
            @media (min-width: 1400px) {
                padding-right: 100px;
            }
        }
        ul {
            list-style: none;
            li {
                position: relative;
                &:before {
                    content: '';
                    border-left: 3px solid grey;
                    position: absolute;
                    height: 10px;
                    left: -16px;
                    top: 7px;
                }
            }
        }
        &-title-image {
            transition: all 500ms;
        }
    }
    &-image {
        background-size: cover;
        z-index: 200;
        height: 440px;
        position: relative;
        @media (min-width: 700px) {
            height: 450px;
        }
        @media (min-width: 800px) {
            height: 500px;
        }
        @media (min-width: 900px) {
            height: 550px;
        }
        @media (min-width: 1000px) {
            height: 650px;
        }
        @media (min-width: 1200px) {
            height: 750px;
        }
        @media (min-width: 1200px) {
            height: 980px;
            width: 35.5%;
            position: fixed;
            right: 0px;
            top: 0px;
        }
    }
    .rock-striation {
        position: absolute;
        width: 31%;
        top: 0px;
        left: 0px;
        z-index: 100;
        pointer-events: none;
    }
}

.section-archive-events {
    padding: 0px 20px;
    margin-top: 40px;
    &:last-child {
        margin-bottom:30px;
    }
    article {
        padding: 10px;
        z-index: 350;
        position: relative;
        width: 100%;
        display: inline-block;
        vertical-align: middle;
        a {
            position: relative;
            display: block;
        }
    }
    @media (min-width: 550px){
        article {
            width: 50%;
        }
    }
    @media (min-width: 800px){
        padding: 0px 100px;
        article {
            padding: 20px;
            width: 33.3333333%;
        }
    }
    @media (min-width: 1500px){
        padding: 0px 50px;
        article {
            padding: 20px;
            width: 25%;
        }
    }
    .event-block,
    .latest-panel--small {
        padding: 20px;
        box-shadow: 0px 0px 30px -15px rgba(0,0,0,0.75);
        border: none;
        .content-section {
            padding: 0px;
            .date-holder {
                padding-top: 10px;
                color: #000;
            }
            h3 {
                padding-bottom: 10px;
                font-size: 18px;
                font-family: Raleway,sans-serif;
            }
        }
    }
}

.section-archive-thought {
    @media (min-width: 1500px){
        padding: 0px 160px;
    }
    position: relative;
    background: none;
    .rock-striation {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 60%;
        z-index: 10;
        pointer-events: none;
    }
}

.form-margin{
    margin-bottom: 2rem;
}
