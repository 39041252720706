//Latest
.section--latest {
    z-index: 395;
    @extend .cover-image;
    position: relative;
    height: unset;
    padding-top: 125px;
    @media (min-width:900px){
        height: 500px;
        padding-top: 0px;
    }
    @media (min-width:1400px){
        height: 600px;
    }
    .event-title {
        position: absolute;
        left: 15px;
        z-index: 5;
        top: 35px;
        h2 {
            display: inline-block;
            margin-right: 18px;
            font-weight: 700;
            color: white;
        }
    }

    .latest-title {
        position: absolute;
        left: 15px;
        z-index: 40;
        top: 35px;
        @media (min-width: 900px) {
            left: 37px;
            top: 9px;
        }
        @media (min-width: 900px) {
            left: 76px;
            top: 9px;
        }
        @media (min-width: 1000px) {
            left: 76px;
            top: 3px;
        }
        @media (min-width: 1250px) {
            left: 76px;
            top: -9px;
        }
        @media (min-width: 1350px) {
            left: 76px;
            top: 5px;
        }
        @media (min-width: 1400px) {
            left: 76px;
            top: 25px;
        }
        h2 {
            display: inline-block;
            margin-right: 18px;
            font-weight: 700;
            @media (max-width: 550px) {
                font-size: 26px;
            }
        }
        a {
            font-size: 18px;
            text-transform: uppercase;
        }
        &.hidden {
            display: none;
        }
    }

    &.hide-gradients {
        &:before {
            content: unset;
        }
        &:after {
            content: unset;
        }
        .gneiss-container {
            background-color: unset;
            .swiper-slide {
                background-color: unset;
            }
        }
    }

    &:before {
        content: "";
        top: -380px;
        background: linear-gradient(to top, rgba(122, 174, 213,0.1) 0%, transparent 100%);
        width: 100%;
        height: 400px;
        display: block;
        position: absolute;
        z-index: 15;
        pointer-events: none;
    }

    &:after {
        content: "";
        bottom: -374px;
        background: linear-gradient(to bottom, rgba(122, 174, 213,0.1) 0%, transparent 100%);
        width: 100%;
        height: 400px;
        display: block;
        position: absolute;
        z-index: 15;
        pointer-events: none;
    }

    .gneiss-container {
        overflow: hidden;
    }

    .latest-links {
        li {
            padding: 0 15px;
        }
    }

    @media(min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        .col-sm-6 {
            margin-bottom: 25px;
        }
    }

    .latest-panel, .latest-panel--small, .latest-panel__content {
        height: 100%;
    }

    .swiper-container-latest {
        width: 100%;
        height: 100%;
        z-index: 30;
        background-color: white;
        padding: 20px 30px;
        @media (min-width: 1200px) {
            padding: 0 80px;
        }
    }
    .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    }
    .single-event {
        z-index: 40;
        padding: 0;
        padding-left: 30px;
        padding-right: 30px;
        max-width: 440px;
        margin: 0 auto;
        @media (min-width: 900px){
            position: absolute;
            right: 0px;
            width: 440px;
            height: 100%;
            padding-left: 0px;
            padding-right: 0px;
            margin: unset;
        }
        @media(min-width: $screen-lg-min) {
            right: 92px;
        }
        .latest-shadow {
            padding: 0px;
            height: 100%;
            position: relative;
            .latest-title {
                h2 {
                    position: absolute;
                    color: white;
                    z-index: 11;
                    padding-top: 15px;
                    padding-left: 25px;
                    font-weight: 600;
                }
                a {
                    font-size: 18px;
                    text-transform: uppercase;
                }
            }
            .latest-data-item {
                background-color: #343e47;
                color: white;
                height: 100%;
            }
            .content-block {
                color: white;
                @media (min-width: 800px) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
                h3 {
                    color: white;
                    padding: 6px;
                    margin-bottom: 0px;
                    @media (min-width: 800px) {
                        padding: 0px;
                        margin-bottom: 10px;
                    }
                }
            }
            .image-block {
                height: 75%;
                .overflow-contain {
                    height: 100%;
                    img {
                        max-width: unset;
                        height: 100%;
                    }
                }
            }
        }
    }
}

@keyframes moveSlideshowMobile {
  100% {
    transform: translateX(calc(-100% + 600px));
  }
}
@keyframes moveSlideshow {
  100% {
    transform: translateX(calc(-100% + 45vw));
  }
}

//Form
.section--form {
    position: relative;
    .gneiss-container {
        z-index: 30;
        padding: 0 25px;
        @media(min-width: $screen-lg-min) {
           padding: 0 80px;
        }
    }
    .rock-striation {
        position: absolute;
        width: 60%;
        top: 0px;
        right: 0px;
        z-index: 10;
        pointer-events: none;
    }
    @extend .cover-image;

    min-height: 400px;
    padding: 30px 10px;
    @media (min-width: 600px){
        padding: 30px 40px;
    }
    @media (min-width: 800px){
        padding: 40px 90px;
    }
    @media (min-width: 1200px){
        padding: 60px 150px;
    }
    @media (min-width: 1500px){
        padding: 120px 240px 0px ;
    }
    color: #fff;
    overflow: hidden;

    h2 {
        margin-top: 0;
        text-align: left;
        color: #000;
        font-weight: 700;
        font-size: 30px;
        @media(min-width: $screen-lg-min) {
            font-size: 42px;
        }
    }
}

$block-size-sm: 430px;

.section--values {
    @extend .cover-image;
    padding: 30px 0;
    @media(min-width: $screen-lg-min) {
        padding: 80px 0 40px 0;
    }

    &__content {
        text-align: left;
        margin-bottom: 20px;
        h2 {
            text-align: center;
            text-transform: uppercase;
        }
        p {
            @media(min-width: $screen-md-min) {
                max-width: 900px;
            }
        }

        @media(min-width: $screen-sm-min) {
            margin-bottom: 40px;
        }
        @media(min-width: $screen-lg-min) {
            margin-bottom: 60px;
        }

    }

    &__blocks {
        .block {
            overflow: hidden;
            margin-bottom: 30px;
            position: relative;

            img {
                width: 100%;
                object-fit: cover;
                height: auto;
            }

            @media(min-width: $screen-sm-min) {
                height: $block-size-sm;
                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
                &:hover {
                    .text {
                        top: 0;
                        bottom: 0;
                    }
                }
            }

            .text {
                padding: 20px;
                background: rgba(0, 0, 0, 0.4);
                text-align: center;
                transition: top 0.6s ease;
                @media(min-width: $screen-sm-min) {
                    position: absolute;
                    top: calc(#{$block-size-sm} - 105px);
                    left: 0;
                    right: 0;

                    p {
                        font-size: 14px;
                    }
                }

                @media(min-width: 1500px) {
                    p {
                        font-size: 16px;
                    }
                }
            }

            h3 {
                font-weight: 700;
                text-transform: uppercase;

                @media(min-width: $screen-lg-min) and (max-width: 1560px) {
                    font-size: 19px;
                }
            }

            hr {
                margin: 20px auto;
            }
        }
    }
}

.section--four-long {
    display: block;
    position: relative;
    width: 100%;
    padding: 0px 20px;
    margin: 70px 0px;
    overflow: hidden;
    @media(min-width: $screen-sm-min) {
       padding: 0px;
    }
    .rock-striation {
        position: absolute;
        width: 30%;
        top: -20px;
        right: -135px;
        z-index: 10;
        pointer-events: none;
    }
    &.extra-padding {
        padding-top: 0;
        padding-bottom: 160px;
        margin-top: 0;
        margin-bottom: 0;
    }

    &-title {
        z-index: 100;
        position: relative;
        @media (min-width:1000px) {
            padding: 10px 240px 30px;
        }

        &.move-away {
            @media (max-width: 800px) {
                transform: none !important;
            }
        }

        h3 {
            color: #7CA0C5;
            font-size: 20px;
            line-height: 20px;
            height: 20px;
            text-transform: uppercase;
            font-weight: bold;
        }
        h2 {
            font-size: 50px;
            line-height: 50px;
            @media (min-width:1000px) {
                font-size: 100px;
                line-height: 102px;
            }
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 0px;
            margin-top: -10px;
        }
    }
    .single {
        padding-left: 0px;
        padding-right: 0px;
        overflow: hidden;
        z-index: 20;
        background-color: white;
        margin-bottom:20px;
        @media(min-width: $screen-sm-min) {
            margin-bottom:0px;
        }
        h3,
        h2,
        p {
            color: black;
        }
        &:hover {
            h3,
            h2,
            p {
                color: white;
            }
        }
        &-four {
            min-height: 400px;
            z-index: 15;
            padding-left: 0px;
            padding-right: 0px;
            background-size: cover;
            transition: 0.3s all ease;
            overflow: hidden;
            opacity: 1;
            filter: grayscale(0);
            @media (min-width:900px){
                opacity: 0.1;
                filter: grayscale(1);
                background-image: none;
            }

            @media(min-width: $screen-lg-min) {
                min-height: 800px;
                transition: all ease-in-out 0.5s;
            }

            &:hover {
                opacity: 1;
                filter: unset;
                z-index: 15;
                .single-four-contents {
                    filter: opacity(1);
                }
            }

            .thevideo {
                height: 100%;
                width: auto;
                left: 0px;
                position: absolute;
                @media (min-width: 1200px) {
                    &-3 {
                        left: -350px;
                    }
                    &-2 {
                        left: -270px;
                    }
                }
            }
        }
        &-four-contents {
            padding: 0 40px;
            z-index: 35;
            pointer-events: none;
            @media (min-width:1000px) {
                bottom: 115px;
            }
            position: absolute;
            filter: opacity(1);

            &.text {
                padding: 0 25px;
                background-color: rgba(255,255,255,0.5);
                bottom: 10px;
                width: 100%;
                @media (min-width: 900px){
                    background-color: unset;
                }
                @media(min-width: $screen-lg-min) {
                    height: 200px;
                }
            }

            h3 {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: -15px;
                z-index: 15;
            }
            h2 {
                font-size: 22px;
                font-weight: 700;
                z-index: 15;
                @media(min-width: $screen-lg-min) {
                    font-size: 32px;
                }
            }
        }
    }
}

.section--head-lvl-2 {
    display: block;
    width: 100%;

    .socials {
        padding: 50px 0px;
        text-align: left;
        @media (min-width: 450px) {
            padding: 50px 20px;
            text-align: right;
        }
    }

    .head-lvl-2-container {
        @extend .container-fluid;

        position: relative;
        padding: 0 30px;

        @media (min-width: 800px) {
            padding: 0 50px;
        }

        @media (min-width: 1600px) {
            padding: 25px 300px;
        }

        .lvl-2-title{
            padding: 20px 0;

            &:after {
                content: "";
                border-bottom: 3px solid #7CA0C5;
                width: 50%;
                display: block;
            }
        }

        .lvl-2-text {
            padding: 20px 0px;

            &-1 {
                padding-right: 25px;
            }

            &-2 {
                padding-left: 0px;
                @media (min-width: 800px) {
                    padding-left: 25px;
                }
            }
        }

        .lvl-2-cta {
            padding: 10px 0;
        }
    }
}

.section--two-fifty-three {
    @extend .container-fluid;
    padding: 40px 2.5%;
    @media (min-width: 1200px){
        padding: 40px 7% 40px 2.5%;
    }
    @media (min-width: 1500px){
        padding: 40px 12.5%;
    }

    .two-fifty-three-container{
        display: block;
    }
    .two-fifty-three-title {
        text-align: left;
        padding-bottom: 20px;

        h2 {
            padding-bottom: 20px;
            font-size: 50px;
        }
        p {
            color: #7CA0C5;
        }
    }

    .two-fifty-three-blocks {
        padding: 0px 10px;
        background-color: rgba(255,255,255,0.6);
        z-index: 200;
    }

    .two-fifty-three-block {
        img {
            width: 100px;
        }
        a {
            font-size: 20px;
            svg {
                margin-left: 10px;
            }
        }
        &.remove-lines {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                &:after {
                    content: none;
                }
            }
        }
        padding: 0px;
        margin-bottom: 45px;
        border-top: 1px solid #a6a6a6;
        @media (min-width: 1200px){
            height: 350px;
        }
        @media (min-width: 992px){
            &:nth-child(1) {
                &:before {
                    content: "";
                    border-top: 7px solid #7CA0C5;
                    position: absolute;
                    top: -7px;
                    left: 0px;
                    width: 130px;
                }
                @media (min-width: 1200px){
                    &:after {
                        content: "";
                        border-right: 1px solid #a6a6a6;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 200px;
                    }
                }
            }
            @media (min-width: 1200px){
                &:nth-child(2) {
                    &:after {
                        content: "";
                        border-right: 1px solid #a6a6a6;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 200px;
                    }
                }
                &:nth-child(4) {
                    &:after {
                        content: "";
                        border-right: 1px solid #a6a6a6;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 200px;
                    }
                }
                &:nth-child(5) {
                    &:after {
                        content: "";
                        border-right: 1px solid #a6a6a6;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 200px;
                    }
                }
                &:nth-child(7) {
                    &:after {
                        content: "";
                        border-right: 1px solid #a6a6a6;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 200px;
                    }
                }
            }
            &:nth-child(1) {
                &:after {
                    content: "";
                    border-right: 1px solid #a6a6a6;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    height: 200px;
                }
            }
            &:nth-child(3) {
                &:after {
                    content: "";
                    @media (min-width: 1200px){
                        content: unset;
                    }
                    border-right: 1px solid #a6a6a6;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    height: 200px;
                }
            }
            &:nth-child(5) {
                &:after {
                    content: "";
                    border-right: 1px solid #a6a6a6;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    height: 200px;
                }
            }
            &:nth-child(7) {
                &:after {
                    content: "";
                    border-right: 1px solid #a6a6a6;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    height: 200px;
                }
            }
        }
        .content, .block-tile {
            padding: 10px 45px 0 25px;
            display: block;
        }
    }
    .two-fifty-three-cta {
        text-align: center;
        margin-bottom: 20px;
        @media (min-width: 550px) {
            margin-bottom: 0px;
        }
        a {
            height: 50px;
            width: 250px;
            background-color: #7CA0C5;
            border-radius: 3px;
            color: #fff;
            display: flex;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            font-size: 18px;
        }
    }
}

.section--head-lvl-2-dark {
    display: block;
    width: 100%;

    .socials {
        padding: 50px 20px;
        text-align: right;
    }

    .head-lvl-2-dark-container {
        @extend .container-fluid;

        position: relative;
        padding: 0 20px;
        background-color: #343e47;

        @media (min-width: $screen-sm-min) {
            padding: 0 $padding-sm;
        }

        @media (min-width: $screen-md-min) {
            padding: 25px 300px 75px;
        }

        .lvl-2-dark-title{
            padding: 20px 0;

            h2 {
                color: white;
            }

            &:after {
                content: "";
                border-bottom: 3px solid #7CA0C5;
                width: 50%;
                display: block;
            }
        }

        .lvl-2-dark-text {
            padding: 20px 0px;

            &-1 {
                padding-right: 25px;
            }

            &-2 {
                padding-right: 25px;
            }

            p  {
                color: white;
            }
        }

        .lvl-2-dark-cta {
            padding: 10px 0;
        }

        .lvl-2-dark-text-wrapper {
            border-bottom: 1px solid #7CA0C5;
            display: inline-block;
            margin-bottom: 50px;
            padding-bottom: 25px;
        }

        .lvl-2-dark-blocks {
            padding: 0px;
        }

        .lvl-2-dark-block {
            padding-left: 0px;
            padding-right: 25px;

            h3 {
                color: white;
            }
            p {
                color: white;
            }
            img {
                height: 150px;
            }
        }
    }
}

.section--descriptor-panel {
    overflow: hidden;
    z-index: 100;
    position: relative;
    .descriptor-panel {
        background-size: contain;
        background-repeat: no-repeat;
        padding: 100px 10px;
        display: flex;
        flex-wrap: wrap;
        @media(min-width: $screen-md-min) {
            background-size: cover;
        }
        @media (min-width: 800px){
            padding: 75px 200px;
        }
    }
    .descript-panel {
        background-color: white;
        box-shadow: 0 0 30px rgba(0,0,0,.2);
        padding: 10px;
        
        margin-bottom: $grid-gutter-width/2;
        @media (min-width: 800px){
            padding: 40px;
            top: 0px;
        }

        @media (min-width: 1200px){
            &.left {
                margin-right: 50%;
            }
            &.right {
                margin-left: 50%;
            }
        }

        h2 {
            font-size: 20px;
            color: #ff6682;
            margin-bottom: 0px;
        }
        h1 {
            margin-top: 0px;
        }
        p {
            padding-bottom: 0px;
        }
        li {
            position: relative;
            list-style: none;
            &:before {
                content: '';
                border-left: 3px solid #98caec;
                position: absolute;
                height: 10px;
                left: -16px;
                top: 7px;
            }
        }
    }
    .cta-container {
        display: flex;
        flex-direction: column;
        @media (min-width: 800px) {
            flex-direction: inherit;
        }
    }
    .descriptor_cta_1 {
        height: 40px;
        width: 175px;
        border-radius: 5px;
        color: white;
        background-color: #7CA0C5;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }
    .descriptor_cta_2 {
        height: 40px;
        width: 175px;
        border-radius: 5px;
        color: #606263;
        background-color: white;
        border: 1px solid #606263;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .secondary-col-panel{
        @media (min-width:1200px){
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
            &.order-lg-2{
                padding-left: $grid-gutter-width*2;
            }
        }
        img{
            max-width: 100%;
            max-height: 100%;
            height: auto;
            object-fit: contain;
        }
    }
    .descript-secondary-lrg{
        color: $brand-supp;
        font-size: 10vw;
        line-height: 10vw;
        margin: 0;
        font-family: $font-heading;
        @media (max-width:767px){
            font-size: 72px;
            line-height: normal;
            min-width: 100%;
        }
        @media (min-width:1440px){
            font-size: 140px;
            line-height: 140px;
        }
    }
    .descript-secondary-sml{
        font-family: $font-heading;
        font-size: 3vw;
        line-height: normal;
        color: $dark-text;
        @media (max-width:767px){
            font-size: 32px;
        }
        @media (min-width:1440px){
            font-size: 42px;
        }
        span, em{
            color: $brand-secondary;
            font-style: normal;
        }
    }
    .descript-secondary-line{
        position: absolute;
        background-color: $brand-secondary;
        height: 7px;
        width: 100%;
        right: 100%;
        top:$grid-gutter-width;
        &.hl-right{
            left: 100%;
            right: auto;
        }
    }
    .graphic-device{
        position: absolute;
        z-index: -1;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left bottom;
        }
    }
    .descript__lower{
        color: $dark-text;
        font-family: $font-heading;
        font-size: 3vw;
        line-height: normal;
        padding-top: $grid-gutter-width;
        padding-bottom: $grid-gutter-width;
        margin-top: $grid-gutter-width;
        margin-bottom: $grid-gutter-width;
        p, ul, li{
            font-family: $font-heading;
            font-size: 2.3vw;
            line-height: normal;
        }
        .txt--lrg{
            p, ul, li{
                font-size: 3vw;
            }
        }
        p{
            margin-bottom: 0;
        }
        span, em{
            color: $brand-supp;
            font-style: normal;
        }
        img{
            max-width: 100%;
            max-height: 100%;
            height: auto;
            object-fit: contain;
        }
        @media (max-width:767px){
            font-size: 32px;
            p, ul ,li{
                font-size: 32px;
            }
            .txt--lrg{
                p, ul, li{
                    font-size: 32px;
                }
            }
        }
        @media (min-width:992px){
            display: flex;
            align-items: center;
            padding: $grid-gutter-width;
        }
        @media (min-width:1440px){
            font-size: 38px;
        }
        .descript__lower-img{
            min-width: 33.3%;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
.page-id-5112 .descriptor-panel{
    background-size: cover;
    background-position: center center;
}
.order-0{
    order: 0;
}
.order-1{
    order: 1;
}
.order-2{
    order: 2;
}
.order-3{
    order: 3;
}
.order-4{
    order: 4;
}
@media (min-width:1200px){
    .order-lg-0{
        order: 0;
    }
    .order-lg-1{
        order: 1;
    }
    .order-lg-2{
        order: 2;
    }
    .order-lg-3{
        order: 3;
    }
    .order-lg-4{
        order: 4;
    }
}

.section--key-specialist-service {
    overflow: hidden;
  .key-specialist-service-container {
    @extend .container-fluid;

    width: 100%;
    @media (min-width: 800px){
        padding: 50px 50px;
    }
    @media (min-width: 1600px){
        padding: 50px 250px;
    }
    display: block;

    .icon-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 45px 0;
      img {
        width: 150px;
      }
    }

    a {
      display:block;
    }

    .more-key-specialist-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 70px 0;

      &:before {
        content: '';
        border-right: 3px solid white;
        height: 40px;
        position: relative;
        left: 52px;
      }

      &:after {
        content: '';
        border-top: 3px solid white;
        width: 40px;
        position: relative;
        right: 70px;
      }

      .more-key-specialist {
        height: 100px;
        width: 100px;
        border-radius: 50px;
        background-color: #7CA0C5;
      }
    }
  }
}

.section--double-image-with-cta {
    overflow: hidden;
    .double-image-with-cta-container {
        @media (min-width: 800px) {
            padding: 150px 50px 50px 50px;
        }
        background-repeat: no-repeat;
        background-size: contain;

        h1 {
            text-align: center;
            &:after {
                content: " ";
                border-bottom: 6px solid #7CA0C5;
                width: 125px;
                display: block;
                margin: 20px auto 75px;
            }
        }

        .double-image-container {

            padding: 0px 30px;

            @media (min-width: 800px){
                padding-left: 10px;
                padding-right: 10px;
                display: flex;
            }
            @media (min-width: 1200px){
                padding-left: 180px;
                padding-right: 180px;
                display: flex;
            }

            .double-image-cont {
                padding-left: 30px;
                padding-right: 30px;
                padding-bottom: 20px;
                @media (min-width: 450px){
                    padding-bottom: 35px;
                    padding-left: 35px;
                    padding-right: 35px;
                }
                @media (min-width: 800px){
                    padding-bottom: 0px;
                    padding-left: 30px;
                    padding-right: 30px;
                }
                svg {
                    transition-duration: 1s;
                }
                &:hover {
                    text-decoration: none;
                    svg {
                        position: relative;
                        transform: translateX(10px);
                    }
                }
            }

            .double-image-img {
                height: 325px;
                @media (min-width:900px) {
                    height: 420px;
                }
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                padding-top: 325px;

                p {
                    padding: 20px;
                    display: inline;
                    color: white;
                    background-color: #7CA0C5;
                    position: relative;
                    right: 20px;
                    bottom: 115px;
                    @media (min-width:900px) {
                        bottom: unset;
                    }

                    &:before {
                        border-color: transparent #444 transparent transparent;
                        border-style: solid;
                        border-width: 0 21px 20px 0;
                        height: 0px;
                        left: 0px;
                        width: 0;
                        top: 61px;
                        content: "";
                        position: absolute;
                        z-index: 1;
                    }
                }
            }
        }

        .double-image-content {
            margin: 0 auto;
            margin-top: 80px;
            text-align: center;
            background-color: rgba(255,255,255,0.7);
            padding: 0px 20px;
            @media (min-width: 450px){
                width: 80%;
                margin-bottom: 80px;
            }
            @media (min-width: 800px){
                width: 50%;
                padding: unset;
            }
            @media (min-width: 1200px){
                width: 35%;
            }
        }

        .double-image-cta {
            height: 40px;
            width: 175px;
            border-radius: 5px;
            color: #7CA0C5;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 20px;
            border: 1px solid #606263;
            position: relative;
            z-index: 300;
        }
    }
}
.sectors {
    .section--double-image-with-cta {
        .double-image-with-cta-container {
            @media (min-width: 800px) {
                padding: 10px 50px 10px;
            }
        }
    }
}

.section--home-section {
    position: relative;
    .rock-striation {
        position: absolute;
        width: 31%;
        top: 0px;
        left: 0px;
        z-index: 10;
        pointer-events: none;
    }
    .home-section-container {
        @extend .container-fluid;
        overflow: hidden;
        padding: 20px 20px 0px 0px;

        @media (min-width: 1000px) {
            padding: 95px 0 100px 75px;
        }
        @media (min-width: 1500px) {
            padding: 95px 0 100px 250px;
        }

        .home-section-left {
            z-index: 15;
            h1 {
                font-size: 48px;
                font-weight: 700;
                line-height: 56px;
                margin-top: 0px;
                line-height: 1.2;
            }
            .title-excerpt {
                color: #353D47;
            }
            h2 {
                color: #7CA0C5;
                font-size: 25px;
                font-weight: 100;
                font-family: $font-primary;
                line-height: 1.3;
                margin-top: 10px;
            }
            p {
                font-size: 16px;
                line-height: 1.6;
                font-weight: 100;
                font-family: $font-primary;
                margin-bottom: 18px;
            }
            a {
                padding: 18px 22px;
                position: relative;
                @media (min-width:900px){
                    font-size: 18px;
                }
                font-size: 16px;
                color: white;
                background-color: #7CA0C5;
                border-radius: 15px;
            }
            &-content {
                padding: 12px;
                padding-left:30px;
                padding-right:30px;
                background-color: rgba(255,255,255,0.8);
                @media (min-width: 900px) {
                    padding-bottom: 30px;
                    padding-left:12px;
                    padding-right:12px;
                }
                @media (min-width: 1200px) {
                    padding-right: 150px;
                }
            }
        }

        .home-section-right {
            z-index: 15;
            padding-left: 15px;
            padding-right: 0px;
            padding-top: 20px;
            @media (min-width: 900px){
                padding-left: 45px;
                padding-right: 15px;
                padding-top: 0px;
            }
            &-container {
                margin-left: 0px;
                margin-right: 0px;
                @media (min-width: 992px){
                    text-align: left;
                    margin-left: -15px;
                    margin-right: -15px;
                    height: 125px;
                }
                @media (max-width: 900px){
                    height: 268px;
                }
                border-radius: 15px 0 0 15px;
                transition: 0.2s;
                .line-title:after {
                    bottom: 11px;
                }

                h2 {
                    margin-bottom:0px;
                }
                a {
                    @media (max-width: 800px) {
                        .icon-container {
                                display: flex;
                                justify-content: left;
                                align-items: left;
                                margin-left:20px;
                        }
                        .home-section-collapse {
                            position: absolute;
                            bottom: 0px;
                            padding: 0 30px;
                        }
                    }
                }
                & p {
                    @media (min-width: 992px){
                        visibility: collapse;
                        padding-right: 30%;
                        padding-top: 5px;
                        margin-bottom: 15px;
                    }
                    font-size: 16px;
                    transition: 0s;
                }
                &:hover {
                    color: white;
                    h2 {
                        color: white;
                    }
                    p {
                        visibility: visible;
                    }
                    a {
                        p {
                            color: white;
                        }
                    }
                    .gneissIcons {
                        filter: brightness(1.5);
                    }
                    path, rect {
                        fill: white;
                        stroke: white;
                        stroke-width: 1;
                    }
                }
                &:nth-child(1) {
                    .line-title {
                        h2 {
                            padding-right:8px;
                        }
                        &:after {
                            background-color: $servicePurple;
                        }
                    }
                    &:hover {
                        background-color: $servicePurple;
                        .line-title {
                            h2 {
                                background-color: $servicePurple;
                            }
                        }
                        .home-section-right-container-image {
                            background-image: url("../images/001-Icon-white.png");
                        }
                    }
                    .home-section-right-container-image {
                        background-image: url("../images/001-Icon-yellow.png");
                    }
                }
                &:nth-child(2) {
                    .line-title {
                        h2 {
                            padding-right:8px;
                        }
                        &:after {
                            background-color: $serviceYellow;
                        }
                    }
                    &:hover {
                        background-color: $serviceYellow;
                        .line-title {
                            h2 {
                                background-color: $serviceYellow;
                            }
                        }
                        background-color: $serviceYellow;
                        .home-section-right-container-image {
                            background-image: url("../images/icon-white.png");
                        }
                    }
                    .home-section-right-container-image {
                        background-image: url("../images/Gneiss-AD-icon.png");
                    }
                }
                &:nth-child(3) {
                    .line-title {
                        h2 {
                            padding-right:8px;
                        }
                        &:after {
                            background-color: $serviceRed;
                        }
                    }
                    &:hover {
                        background-color: $serviceRed;
                        .line-title {
                            h2 {
                                background-color: $serviceRed;
                            }
                        }
                        background-color: $serviceRed;
                        .home-section-right-container-image {
                            background-image: url("../images/040-Icon-white.png");
                        }
                    }
                    .home-section-right-container-image {
                        background-image: url("../images/040-Icon-brand-colour.png");
                    }
                }
                &:nth-child(4) {
                    .line-title {
                        h2 {
                            padding-right:8px;
                        }
                        &:after {
                            background-color: $serviceBlue;
                        }
                    }
                    &:hover {
                        background-color: $serviceBlue;
                        .line-title {
                            h2 {
                                background-color: $serviceBlue;
                            }
                        }
                        background-color: $serviceBlue;
                        .home-section-right-container-image {
                            background-image: url("../images/014-alt-Icon-white.png");
                        }
                    }
                    .home-section-right-container-image {
                        background-image: url("../images/014-alt-Icon-turquise.png");
                    }
                }
                &:nth-child(5) {
                    .line-title {
                        h2 {
                            padding-right:8px;
                        }
                        &:after {
                            background-color: $serviceLightBlue;
                        }
                    }
                    &:hover {
                        background-color: $serviceLightBlue;
                        .line-title {
                            h2 {
                                background-color: $serviceLightBlue;
                            }
                        }
                        background-color: $serviceLightBlue;
                        .home-section-right-container-image {
                            background-image: url("../images/011-Icon-white.png");
                        }
                    }
                    .home-section-right-container-image {
                        background-image: url("../images/011-Icon-purple.png");
                    }
                }
                &:nth-child(6) {
                    .line-title {
                        h2 {
                            padding-right:8px;
                        }
                        &:after {
                            background-color: $serviceGreen;
                        }
                    }
                    &:hover {
                        background-color: $serviceGreen;
                        .line-title {
                            h2 {
                                background-color: $serviceGreen;
                            }
                        }
                        background-color: $serviceGreen;
                        .home-section-right-container-image {
                            background-image: url("../images/017-Icon-white.png");
                        }
                    }
                    .home-section-right-container-image {
                        background-image: url("../images/017-Icon-blue.png");
                    }
                }
                &:nth-child(7) {
                    .line-title {
                        h2 {
                            padding-right:8px;
                        }
                        &:after {
                            background-color: $serviceOrange;
                        }
                    }
                    &:hover {
                        background-color: $serviceOrange;
                        .line-title {
                            h2 {
                                background-color: $serviceOrange;
                            }
                        }
                        background-color: $serviceOrange;
                        .home-section-right-container-image {
                            background-image: url("../images/017-Icon-white.png");
                        }
                    }
                    .home-section-right-container-image {
                        background-image: url("../images/017-Icon-blue.png");
                    }
                }
                .gneissIcons {
                    width: 120px;
                    padding: 10px;
                }
                &-image {
                    height: 100px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 46%;
                    @media (min-width: 900px){
                        background-position: center;
                    }
                    position: relative;
                    top: 8px;
                }
            }
            div {
                padding-right: 0px;
                padding-left: 0px;
            }
            h2 {
                font-size: 20px;
            }
            img {
                max-width: 100px;
            }
            .home-section-collapse {
                padding-left: 0px;
            }
            .home-service-text {
                height: 105px;
            }
        }
    }
}

.section--values {
    position: relative;
    @media (min-width: 900px){
        padding: 95px 75px;
    }
    @media (min-width: 1200px){
        padding: 95px 100px;
    }
    @media (min-width: 1500px){
        padding: 95px 200px;
    }
    .rock-striation {
        position: absolute;
        width: 60%;
        top: -100px;
        right: 37%;
        z-index: 10;
        pointer-events: none;
    }
    .values-container {
        @extend .container-fluid;
        .col-md-12,
        .col-md-3 {
            z-index: 15;
        }
        &-head {
            width: 100%;
            @media (min-width: 900px) {
                width: 380px;
            }
            @media (max-width: 800px) {
                transform: none !important;
            }
            margin: 0 auto;
            z-index: 15;
            h3 {
                font-size: 18px;
                font-weight: 700;
                text-align: center;
                color: #7CA0C5;
                margin-bottom: 0px;
                @media (min-width: 900px) {
                    font-size: 20px;
                    text-align: right;
                    margin-bottom: 15px;
                }
            }
            h2 {
                font-size: 33px;
                font-weight: 700;
                text-align: center;
                color: black;
                letter-spacing: -0.025em;
                margin-top: 0px;
                line-height: 60px;
                @media (min-width: 550px) {
                    font-size: 40px;
                }
                @media (min-width: 900px) {
                    font-size: 70px;
                    margin-bottom: 40px;
                }
                @media (min-width: 1400px) {
                    font-size: 100px;
                }
            }
        }
        &-blocks {
            display: table;
            position: relative;
            z-index: 30;
            .block {
                padding: 20px;
                bottom: 0px;
                transition:0.3s all ease;
                @media(min-width: $screen-lg-min){
                    padding-left: 70px;
                }
                &:hover {
                    bottom: 10px;
                    img {
                        filter: brightness(140%);
                    }
                }
                &-value {
                    width: fit-content;
                    margin: 0 auto;
                }
            }
            img {
                max-width: 150px;
                transition: all 0.2s;
                filter: brightness(100%);
            }
            h2 {
                font-size: 22px;
                text-align: center;
            }
            p {
                font-size: 16px;
                line-height: 1.6;
                text-align: center;
                @media (min-width:550px) {
                    padding: 0 25px;
                }
                @media(min-width: $screen-lg-min) {
                    font-size: 20px;
                }
            }
        }
    }
}

.section--success-stories {
    overflow: hidden;
    position: relative;
    padding: 50px 10px;
    z-index: 400;
    @media (min-width:900px) {
        padding: 75px 80px 250px;
    }
    .rock-striation {
        position: absolute;
        width: 60%;
        top: -38px;
        right: 13%;
        z-index: 10;
        pointer-events: none;
    }
    h3 {
        font-size: 18px;
    }
    .swiper-container-success-stories {
        z-index: 15;
        position: relative;
        @media (min-width: 900px){
            height: 650px;
        }
        .stories-content {
            .success-stories-above {
                display: inline-block;
                width: 100%;
            }
            .success-stories-shadow {
                box-shadow: 0 0 17px -6px #000;
                width: fit-content;
                width: 300px;
                height: 220px;
                background: white;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.6s;
                @media (min-width:900px){
                    width: 420px;
                }
                img {
                    width: 150px;
                }
                .success-stories-data-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .success-stories-below {
                display: inline-block;
                width: 100%;
            }
            .title {
                font-size: 30px;
                font-weight: 600;
                text-align: left;
                margin-bottom: 5px;
            }
            .year {
                font-size: 16px;
                text-align: left;
            }
            .description {
                font-size: 18px;
                text-align: left;
                @media (max-width: 900px) {
                    height: 36px;
                }
            }
            .capital p {
                font-weight: 100;
                font-size: 18px;
                padding-top: 25px;
            }
        }
        .swiper-pagination {
            position: relative;
            top: 25px;
        }
        .swiper-slide-active {
            .stories-content {
                .success-stories-shadow {
                    @media (min-width:900px){
                        width: 400px;
                        height: 400px;
                    }
                }
            }
        }
    }
    .swiper-slide,
    .swiper-slide-next,
    .swiper-slide-previous {
        .title,
        .year,
        .description,
        .capital {
            visibility: hidden;
        }
        @media (min-width:900px){
            top: 80px;
        }
    }
    .swiper-slide-active {
        .title,
        .year,
        .description,
        .capital {
            visibility: unset;
        }
        top: 0px !important;
    }
}

.section--thought-leadership,
.thought-leadership {
    .thought-leadership-container {
        &-inner {
            @media (min-width: 768px) {
                padding: 10px 30px 30px 30px;
            }
            position: relative;
        }
        .thought-leadership-cont {
            padding: 30px;
            @media (min-width: 768px) {
                padding: 15px;
            }
            &-img {
                @media (min-width: 768px) {
                    width:65%;
                    max-width: 600px;
                }
                max-width: 100%;
                max-height: 320px;
                box-shadow: 0 4px 17px 0 rgba(0,0,0,.12);
            }
            .thought-leadership-content {
                h3 {
                    padding-bottom: 10px;
                    font-size: 16px;
                    margin-top: 0;
                    margin-bottom: 0;
                }
                position: relative;
                width: 100%;
                height: 40%;
                z-index: 20;
                top: 200px;
                left: 30%;
                @media (min-width: 768px) {
                    left: 65%;
                    width: 50%;
                    position: absolute;
                    top: 50%;
                }
                padding: 10px;
                background-color: white;
                box-shadow: 0 0 38px -15px #959595;
                transform: translate(-30%, -55%);
                &-inner {
                    max-height: 100%;
                    height: 225px;
                    @media (min-width: 768px) {
                        height: 101px;
                        right: 0px;
                    }
                    background-size: contain;
                    background-repeat:no-repeat;
                    background-position: center;
                }
                &-img {
                    max-height: 100%;
                    height: 100%;
                }
                .read-full-link {
                    text-align: right;
                    color: #000;
                    svg {
                        color: #000;
                    }
                    p {
                        font-size: 9px;
                        font-family: Proza Libre,sans-serif;
                        color: #000;
                        margin-left: 10px;
                        display: inline-block;
                    }
                    a {
                        color: #000;
                        &:hover {
                            color: white;
                        }
                    }
                }
                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.thought-leadership {
    width: 60%;
}

.section--team {
    .dropdown-container {
        position: relative;
        z-index: 400;
        display: none;
        .categorySelector {
            right: 0px;
            top: -55px;
            position: absolute;
            margin-right: 18px;
        }
    }
    &.team-page {
        @media (min-width:1200px){
            margin-top: -350px;
        }
    }
    &-container {
        @media (min-width:1200px){
            padding: 0 200px;
        }
        .team-row {
            .team-nav {
                display: none;
                @media (min-width:1200px){
                    width: 67%;
                    display: block;
                }
                @media (min-width:1400px){
                    width: 49%;
                    display: block;
                }
                @media (min-width:1650px){
                    width: 59.5%;
                }
                @media (min-width:1750px){
                    width: 40%;
                }
                float: left;
                height: 300px;
            }
            .team-col {
                padding: 15px;
                width: 100%;
                display: inline-block;
                position: relative;
                z-index: 395;
                @media (min-width:400px){
                    width: 80%;
                    margin: 0 auto;
                }
                @media (min-width:600px){
                    width: 49.5%;
                    margin: unset;
                }
                @media (min-width:775px){
                    width: 33%;
                }
                @media (min-width:1400px){
                    width: 24.3%;
                }
                @media (min-width:1650px){
                    width: 19.5%;
                }
                z-index: 395;
                .team-member-block {
                    padding: 10px;
                    border: 1px solid #B8B8B8;
                    overflow: hidden;
                    box-shadow: 0px 0px 30px -12px rgba(0,0,0,0.75);
                    background-color: #fff;
                    position: relative;
                    .image-section {
                        @media (min-width: 800px) {
                            height: 280px;
                        }
                        height: 330px;
                        background-size: cover;
                        background-position: center;
                        position: relative;
                        .content-section {
                            background-color: rgba(61,125,202, 0.6);
                            bottom: 0;
                            min-height: 70px;
                            position: absolute;
                            width: 100%;
                            padding: 10px 5px;
                            padding-left:10px;
                            h2 {
                                padding-bottom: 8px;
                            }
                            h2 {
                                font-family: $font-primary;
                                font-size: 15px;
                                color: #fff;
                                margin: 0;
                                font-weight: 700;
                            }
                            h3 {
                                font-family: $font-primary;
                                font-size: 12px;
                                color: #fff;
                                margin: 0;
                                font-weight: normal;
                            }
                        }
                        &:hover {
                            & ~ .overlay-section {
                                display: block;
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                    .overlay-section {
                        // display: none;
                        font-family: $font-primary;
                        opacity: 0;
                        visibility: hidden;
                        height: 330px;
                        @media (min-width: 800px){
                            height: 280px;
                        }
                        background-color: #3D7DCA;
                        margin: 0px;
                        position: absolute;
                        top: 10px;
                        width: calc(100% - 20px);
                        transition: opacity 600ms, visibility 600ms;
                        img {
                            max-width: 100%;
                            padding: 10px;
                        }
                        .mini-profile {
                            h2 {
                                font-size: 16px;
                                color: white;
                                border-bottom: 1px solid #5e9ce6;
                                padding-bottom: 5px;

                            }
                            h3 {
                                font-size: 16px;
                                color: white;
                                margin-top: 15px;
                            }
                            a {
                                color: white;
                            }
                            .name-and-job {
                                margin-left: -10px;
                                padding-left: 0px;
                                padding-right: 20px;
                            }
                            .full-profile,
                            .linkedIn {
                                p {
                                    width: 90%;
                                    border: 1px solid #5e9ce6;
                                    padding: 6px;
                                    margin: 10px auto;
                                    color: white;
                                    font-size: 14px;
                                }
                                span {
                                    margin-right: 15px;
                                    padding-right: 10px;
                                    border-right: 1px solid #5e9ce6;
                                }
                            }
                        }
                        &:hover {
                            // display: block;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

.section--who {
    margin: 40px;
    &.team-page-who {
        display: inline-block;
        @media (min-width:1200px){
            margin-top: -50px;
        }
    }
    &-container {
        padding: 0 20px;
        @media (min-width: 768px){
            padding: 80px 20px 0px;
        }
        @media (min-width: 900px){
            padding: 150px 200px 75px;
            background-attachment: fixed;
            background-size: cover;
        }
        .who-title {
            h2 {
                text-align: center;
                color: #000;
                text-transform: uppercase;
            }
        }
        .background-panel .team__tabs{
            a strong {
                color: #000;
            }
        }
        .logo-wrapper {
            box-shadow: 0px 0px 30px -12px rgba(0,0,0,0.75);
        }
    }
}

.section--map {
    .row {
        margin: 0px;
    }
    .map-container {
        height: 850px;
        @media (min-width: 1200){
            display: inline-block;
        }
        padding: 0px;

        #map-left,
        #map-right {
            height: 100%;
        }

        #map-left {
            border-right: 1px solid #7CA0C5;
        }
        #map-right {
            border-left: 1px solid #7CA0C5;
        }
        .map-info-container {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            .map-info {
                background-color: #343E47;
                padding: 25px;
                position: relative;
                bottom: 125px;
                z-index: 200;
                @media (max-width: 768px){
                    border-right: 1px solid #000;
                }
                @media (min-width: 992px){
                    max-width:475px;
                }
                h2 {
                    color: white;
                    font-size: 14px;
                    font-weight: 100;
                    @media (min-width: 768px){
                        font-size: 18px;
                    }
                    @media (min-width: 900px){
                        font-size: 40px;
                    }
                }
                p {
                    color: white;
                    font-size: 14px;
                    margin-bottom: 5px;
                    position: relative;
                    @media (min-width: 900px){
                        font-size:18px;
                    }
                }
                &-left {
                    padding-left: 0px;
                    p {
                        &:last-child {
                            font-weight: bold;
                        }
                        text-align: left;
                    }
                }
                .map-info-bottom {
                    text-align: right;
                    a {
                        display:block;
                        color: white;
                        &:hover {
                            text-decoration: none;
                            color: $brand-secondary;
                        }
                    }
                    p:hover {
                        text-decoration: none;
                        color: $brand-secondary;
                    }
                }
                .svg-inline--fa {
                    position: relative;
                    left: -7px;
                    top: 4px;
                    font-size: 28px;
                    color: white;
                    @media (min-width:900px){
                        position: relative;
                        left: -10px;
                        top: 4px;
                    }
                }
            }
        }
    }
}

.section--key-event {
    .key-event-container {
        display: flex;
        position: relative;
        @media (min-width:900px){
            padding: 0px 0px 0px 170px;
        }
        background-size:contain;
        background-repeat:no-repeat;
        box-shadow: 0 0 38px -15px #959595;
        margin-top: 38px;
        &-info {
            background-color: white;
            padding: 25px;
            z-index: 100;
            height: 100%;
            img {
                display: none;
            }
            h2 {
                font-size: 24px;
            }
            p {
                font-size: 15px;
                font-weight: normal;
                color: #000;
            }
            span {
                svg {
                    font-size: 18px;
                    position: relative;
                    right: 8px;
                    top: 2px;
                }
            }
            .event-date,
            .event-location {
                margin-bottom: 30px;
                color: black;
                p {
                    padding-left: 10px;
                }
            }
        }
        &-ribbon {
            padding: 12px 20px;
            display: none;
            color: white;
            background-color: #7CA0C5;
            position: absolute;
            left: -21px;
            top: 60%;
            @media(min-width: 900px){
                display: inline;
            }


            &:before {
                border-color: transparent #444 transparent transparent;
                border-style: solid;
                border-width: 0 21px 14px 0;
                height: 0;
                left: 0;
                width: 0;
                top: 49px;
                content: "";
                position: absolute;
                z-index: 1;
            }

            &.upcoming-custom {
                background-color: $brand-primary3;
            }

            &.past-custom {
                background-color: $brand-primary2;
            }
        }
    }
}

.section--team-profile {
    .team-profile {
        background-color: #7CA0C5;
        margin: 0px;
        position: relative;
        transition: opacity 600ms, visibility 600ms;
        box-shadow: 0 4px 17px 0px rgba(0,0,0,.12);
        img {
            max-width: 100%;
            padding: 30px;
        }
        .mini-profile {
            h2 {
                font-size: 30px;
                font-weight: 700;
                color: white;
                border-bottom: 3px solid #8dc5ef;
                padding-bottom: 15px;
                margin-top:30px;
                text-transform: uppercase;
            }
            h3 {
                font-size: 18px;
                font-weight: 700;
                color: white;
                margin-top: 19px;
            }
            a {
                color: white;
            }
            .name-and-job {
                margin-left: -10px;
                padding-left: 0px;
                padding-right: 20px;
            }
            .full-profile,
            .linkedIn {
                p {
                    border: 2px solid #8dc5ef;
                    padding: 12px 12px 12px 18px;
                    margin: 10px auto;
                    color: white;
                }
                span {
                    margin-right: 15px;
                    padding-right: 15px;
                    border-right: 2px solid #8dc5ef;
                }
            }
        }
    }
}

.section--social-channels {
    vertical-align: top;
    position:relative;
    .social-wrap {
        @media (min-width: 1024px){
            padding-left: 24%;
        }
    }
    .socials {
        background:rgba(255,255,255,0.95);
        padding: 20px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 4px 17px rgba(0,0,0,.12);
        h2 {
            font-size: 26px;
        }
        a {
            font-size: 21px;
            list-style: none;
            margin: 0;
            padding: 10px 0;
            font-weight: normal;
        }
        .subs,
        .follows {
            a {
                &:last-child {
                    border-top: 1px solid #e0e0e0;
                    border-bottom: 1px solid #e0e0e0;
                }
                border-top: 1px solid #e0e0e0;
                display: block;
                width: 100%;
            }
        }
        .social-link-sub,
        .social-link-follow {
            float: left;
            text-align: left;
            svg {
                float: right;
                margin-top: 6px;
            }
        }
    }
    @media (min-width: 1200px){
        &:after {
            content:'';
            position:absolute;
            background-image:url(../images/svg/striation-graphic-float.svg);
            background-repeat:no-repeat;
            background-size:contain;
            width:100%;
            height: 480px;
            right: -100%;
            top: -60px;
            z-index:-1;
        }
    }
}

.section-50-desktop {
    padding-left:20px;
    padding-right:20px;
    background:transparent;
  @media(min-width: $screen-lg-min) {
    width: 49%;
    display: inline-block;
    vertical-align: top;
    padding-left:10%;
    padding-right:30px;
    margin-bottom:50px;
  }
  @media (min-width: 1440px){
    &:nth-child(odd){
        padding-right:10%; padding-left:30px;
    }
  }
}

.line-title {
    display:block;
    position:relative;
    h1,h2 {
        display:inline-block;
        width:auto;
        background:#fff;
        z-index: 100;
        position: relative;
        @media (min-width: 992px) {
            font-size:30px;
        }
    }
    &:after {
        content:'';
        display:block;
        height:3px;
        position:absolute;
        bottom:27px;
        right:0;
        left:0;
        width:100%;
        background:#7CA0C5;
        z-index: 90;
    }
}

.simple-content-section {
    padding: 10px 25px;
    @media (min-width: 800px) {
        padding: 10px 100px;
    }
    @media (min-width: 1200px) {
        padding: 10px 150px;
    }
    @media (min-width: 1500px) {
        padding: 10px 250px 70px;
    }
}
.page-template-template-covid {
    .simple-content-section {
        padding: 20px 25px;
        @media (min-width: 800px) {
            padding: 70px 100px;
        }
        @media (min-width: 1200px) {
            padding: 70px 150px;
        }
        @media (min-width: 1500px) {
            padding: 70px 250px 70px;
        }
    }
}
.section--logo-tile {
    position:relative;
    padding: 15px;

    .title-and-filters {
        padding-right: 80px;
        display: inline-flex;
        align-items: baseline;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 991px) {
            display: block;
        }

        .filter-checkbox {
            margin-right: 10px;
        }

        .filter-label {
            margin-right: 20px;
        }
    }
    @media (min-width: 1500px) {
        padding: 0px;
        padding-bottom:40px;
    }
    h1 {
        color: $serviceLightBlue;
        z-index:10;
        position:relative;
        @media (min-width: 1200px) {
            padding-left:80px;
            margin-top:60px;
            margin-bottom:70px;
            font-weight:normal;
        }
        strong {
            color: #353D47;
            font-weight:normal;
        }
    }
    .small {
        margin-top:15px;
    }
    .swiper-container-latest--logo-tile {
        overflow:hidden;
        padding-bottom: 10px;
        @media (min-width: 1200px) {
            padding-left:80px;
        }
        .swiper-slide{
            height: auto;
        }
        .tile-content {
            position:relative;
            text-align:center;
            padding-left:60px;
            background:#fff;
            padding-right:20px;
            border:1px solid $serviceLightBlue;
            box-shadow:0 4px 17px rgba(0,0,0,.12);
            margin:10px;
            min-height: 300px;
            height: 100%;
                @media (min-width: 1200px) {
                    margin:10px 10%;
                    margin-left:0;
                }
            &:before{
                content:'';
                position:absolute;
                left:0;
                width:40px;
                height:100%;
                background: $serviceLightBlue;
            }
            .status {
                position:absolute;
                left: -2px;
                top: 43%;
                font-weight:bold;
                transform: rotate(-90deg);
                text-align: center;
                color:#fff;
            }
            .sub-icon {
                position:absolute;
                width:75px;
                height:75px;
                border-radius:50%;
                top: -11px;
                left: 7px;
                border:1px solid $serviceLightBlue;
                background: #fff;
                overflow: hidden;
                img {
                    margin-bottom:0px;
                }
            }
            img {
                max-width:100%;
                height: auto;
                margin-top:5px;
                margin-bottom: 25px;
            }
            p {
                margin-bottom:5px;
                font-size: 16px;
                strong {
                   color:$serviceLightBlue;
                }
            }
        }
    }
    .rock-striation{
    position: absolute;
    width: 60%;
    top: -38px;
    right: 13%;
    z-index: 0;
    pointer-events: none;
    }
    .swiper-button-next,
    .swiper-button-prev {
        display:block;
        width: 26px;
        height: 25px;
        margin-top: 14px;
        @media(min-width: 750px){
            display:none;
        }
    }
}

.home-section-right {
    .home-section-right-container {
        @media (min-width: 1200px) {
            opacity:0;
        }
        transition:0.3s all ease;
    }
}

.home-section-right.aos-animate {
    .home-section-right-container {
        opacity:1;
        animation-name: slide-from-right;
        animation-duration: 1.2s;
        animation-timing-function: ease-in;
        &:nth-child(1){
            animation-delay: 150ms;
        }
        &:nth-child(2){
            animation-delay: 300ms;
        }
        &:nth-child(3){
            animation-delay: 450ms;
        }
        &:nth-child(4){
            animation-delay: 450ms;
        }
        &:nth-child(5){
            animation-delay: 600ms;
        }
        &:nth-child(6){
            animation-delay: 750ms;
        }
        &:nth-child(7){
            animation-delay: 900ms;
        }
    }
}

.simple-content-content {
    p {
        font-size: 20px;
    }
}
