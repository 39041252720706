.page-template-template-team-new {
  .banner {
    .tagline-panel {
      bottom: 325px !important;

      @media(max-width:992px) {
        bottom: 190px !important;
      }

      @media(max-width:768px) {
        bottom: 0 !important;
        text-align: center;
      }
    }
  }
}

.single-team{
    .section--latest {
        padding-bottom: 25px;
    }
    .latest-title{
        @media (min-width:992px){
            margin-top: 20px;
        }
        z-index: 400;
    }
    .swiper-container-latest{
        z-index: 10;
    }
    section.section--logo-tile{
        background: #f2f7fb;
        margin-bottom: -20px;
        margin-top: 40px;
        z-index: 400;
    }
    section.section--news-row~.section--logo-tile-row section.section--logo-tile{
        margin-top: 0;
        margin-top: 0;
    }
    .swiper-pagination{
        display: none;
    }

    .mini-profile>.row{
        max-height: 60%;
        .mini-profile-img{
            flex-direction: column;
            align-content: flex-start;
            img{
                min-height: 100%;
            }
        }
    }
}

.single-member-cont {
  @extend .container-fluid;

  @media(min-width: $screen-md-min) {
    margin-top: -180px;
  }

  @media(min-width: $screen-lg-min) {
    margin-top: -350px;
  }

  @media (min-width: 1200px) {
    padding: 0px 50px;
  }

  @media (min-width: 1600px) {
    padding: 0px 260px;
  }

  .signpost {
    display: none;

    @media (min-width: 1600px) {
      display: block;
    }

    z-index: 800;
    top: 380px;
    position: absolute;
    left: 160px;
  }

  .signpost .scroller {
    position: relative;
    display: block;
    width: 10px;
    height: 242px;
    margin-top: 5px;
    background: #7CA0C5;
    float: right;
    margin-right: 25px;
    animation: arrow-scroll 2.2s infinite;
  }

  .vertical-text {
    text-transform: uppercase;
    transform: rotate(90deg);
    font-family: 'Proza Libre', sans-serif;
    transform-origin: right top 0;
    margin-right: -15px;
    float: right;
    color: #000;
    font-size: 16px;
  }

  .custom-single-member {

    @media (max-width: 1200px) {
      margin-left: -5px;
      margin-right: -5px;
      padding-left: 10px;
      padding-right: 10px;
    }

    @extend .row;

    .custom-single-member-container {
      z-index: 120;

      @media (min-width: 800px) and (max-width: 992px) {
        margin-bottom: 70px;
      }
    }

    .text-area {
      @media(min-width: $screen-md-min) {
        padding-left: 40px;
      }

      @media (min-width: 1200px) {
        padding-left: 0;
        padding-right: 120px;
      }

      .header-section {
        h2 {
          font-size: 24px;
          text-transform: uppercase;
          margin-bottom: 0px;
          color: $brand-secondary;
        }

        h1 {
          font-size: 40px;
          text-transform: uppercase;
          margin-top: 0px;
          margin-bottom: 30px;

          @media (min-width: 1200px) {
            font-size: 60px;
          }
        }
      }

      .description {
        border-bottom: 1px solid grey;
        margin-bottom: 45px;

        p {
          &:nth-child(1) {
            padding: 15px 0px;
            border-top: 1px solid grey;
            border-bottom: 1px solid grey;
            margin-bottom: 35px;
          }
        }
      }

      .socials {
        padding-right: 10px;
        padding-left: 0px;

        span {
          margin-right: 5px;
        }
      }

      .linkedIn-btn {
        @media (max-width: 800px) {
          padding: 0;
          margin-top: 30px;
          margin-bottom: 30px;
          left: 6px;
        }

        @media (min-width: 800px) {
          float: right;
          text-align: right;
        }
      }

      .socials,
      .linkedIn-btn {
        width: 100%;
        display: inline-block;

        @media (min-width: 800px) {
          width: 50%;
        }
      }

      .linkedIn-link {
        display: inline-block;
        font-size: 16px;
        margin-bottom: 15px;
        position:relative;
        @media (min-width: 800px) {
          font-size: 18px;
        }

        @media (min-width: 992px) {
          font-size: 16px;
        }

        border: 1px solid grey;
        color: black;
        padding: 16px 16px 16px 65px;
        border-radius: 5px;

        span {

          position:absolute;
          left:0;
          top:0;
          height:100%;

          color: white;
          background-color: #7CA0C5;
          border-radius: 4px 0 0 4px;
          padding: 17px;
        }
      }
    }

    .image-section {
      position: relative;
      height: 320px;
      background-size: cover;
      background-position: center;
      z-index: 390;
      margin-bottom: 140px;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;

      @media(min-width: 400px) {
        height: 375px;
      }

      @media(min-width: 800px) {
        margin-bottom: 0px;
        height: 700px;
        max-width: 620px;
      }

      @media(min-width: 992px) {
        font-size: 16px;
      }

      @media(min-width: 1100px) {
        margin-left: unset;
        margin-right: unset;
      }

      &-text {
        position: absolute;
        bottom: 30px;
        margin-bottom: -130px;

        @media (min-width: 800px) {
          margin-bottom: 0px;
        }

        p {
          position: relative;
          width: 90%;
          margin: 0 auto;
          background-color: #7CA0C5;
          color: white;
          padding: 22px;
        }
      }
    }

    .back-to-team {
      position: relative;
      width: 250px;

      @media(min-width: 800px) {
        width: 50%;
      }

      background-color: #7CA0C5;
      color: #fff;
      border-radius: 5px;
      padding: 12px;
      margin: 60px auto;
      display: flex;
      font-size: 18px;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 15px;
      }
    }
  }
}

.other-team-members {
  height: 700px;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 700px;
    background-image: url('https://d1ssu070pg2v9i.cloudfront.net/pex/gneiss_2018/2019/04/12101346/iStock-646173116.jpg');
    background-position: center;
    background-size: cover;
    filter: brightness(0.8);
  }

  .other-team-members-title {
    width: 100%;
    text-align: center;
    position: relative;
    top: 170px;

    h2 {
      color: white;
      font-weight: 700;
      font-size: 45px;
    }
  }

  .swiper-container-other-team {
    width: 100%;
    height: fit-content;
    position: relative;
    top: 280px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      width: 300px;

      .team-member-block {
        padding: 10px;
        border: 1px solid #B8B8B8;
        overflow: hidden;
        box-shadow: 0px 0px 30px -12px rgba(0, 0, 0, 0.75);
        background-color: rgba(255, 255, 255, 1);
        position: relative;
        width: 100%;

        .image-section {
          height: 250px;
          background-size: cover;
          background-position: center;
          position: relative;

          .content-section {
            background-color: rgba(61, 125, 202, .6);
            bottom: 0;
            position: absolute;
            width: 100%;
            min-height: 60px;
            padding: 10px 10px;
            text-align: left;

            h2 {
              font-size: 13px;
              color: #fff;
              margin: 0;
              font-weight: 700;
              font-family: $font-primary;
            }

            h3 {
              font-size: 11px;
              color: #fff;
              margin: 0;
              font-weight: normal;
              font-family: $font-primary;
            }
          }

          &:hover {
            &~.overlay-section {
              display: block;
              opacity: 1;
              visibility: visible;
            }
          }
        }

        .overlay-section {
          // display: none;
          opacity: 0;
          visibility: hidden;
          height: 250px;
          background-color: $serviceBlue;
          margin: 0px;
          position: absolute;
          top: 10px;
          width: calc(100% - 20px);
          transition: opacity 600ms, visibility 600ms;

          img {
            max-width: 100%;
            object-fit:cover;
            padding: 10px;
          }

          .mini-profile {
              display: flex;
              flex-direction: column;
              height: 100%;
              >.row{
                  display: flex;
              }
            .mini-profile-img {
                display: flex;
              @media(min-width: 1030px) and (max-width: 1199px) {
                max-height: 150px;
                overflow: hidden;
                margin-bottom: 10px;
              }
            }

            h2 {
              font-size: 16px;
              color: white;
              border-bottom: 1px solid #5e9ce6;
              padding-bottom: 5px;
              font-weight: bold;
              font-family: $font-primary;
            }

            h3 {
              font-size: 16px;
              color: white;
              margin-top: 15px;
              font-family: $font-primary;
            }

            a {
              color: white;
            }

            .name-and-job {
              margin-left: -10px;
              padding-left: 0px;
              padding-right: 20px;
              text-align: left;
            }

            .full-profile,
            .linkedIn {
              p {
                width: 90%;
                border: 1px solid #5e9ce6;
                padding: 6px;
                margin: 10px auto;
                color: white;
                font-size: 14px;
              }

              span {
                margin-right: 15px;
                padding-right: 10px;
                border-right: 1px solid #5e9ce6;
              }
            }
          }

          &:hover {
            // display: block;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
