.light {
  .main-logo-container {
    background-image: url("../images/logo-black.png") !important;
  }
  svg {
    color: #fff;
  }
  #toolbar .search-form .fa-search {
    color: black;
  }
  #toolbar .search-form .search-field {
    @media (max-width: 1200px) {
      background: rgba(234, 234, 234, 0.6);
    }
  }
  .signpost {
    top: 170px;
    left: -80px;
  }
  form.search-form input.search-field {
    color: black !important;
    ::placeholder {
      color: black !important;
      opacity: 1;
    }
    &::placeholder {
      color: black !important;
      opacity: 1;
    }
    ::-webkit-input-placeholder {
      color: black !important;
      opacity: 1;
    }
    ::-moz-placeholder {
      color: black;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: black;
      opacity: 1;
    }
  }
}
.dark {
  svg {
    color: white;
  }
  #toolbar .search-form .fa-search {
    color: white;
  }
}

/* Chrome, Safari, Opera */
@-webkit-keyframes arrow-scroll {
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  25% {
    transform: scaleY(1);
    transform-origin: top;
  }
  50% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
  75% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
  100% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
}
/* Standard syntax */
@keyframes arrow-scroll {
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  25% {
    transform: scaleY(1);
    transform-origin: top;
  }
  50% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
  75% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
  100% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
}

/* Chrome, Safari, Opera */
@-webkit-keyframes loader {
  0% {
    opacity: 0;
    left: -100%;
  }
  10% {
    opacity: 1;
    left: -100%;
  }
  90% {
    opacity: 1;
    left: 200%;
  }
  100% {
    opacity: 0;
    left: 200%;
  }
}
/* Standard syntax */
@keyframes loader {
  0% {
    opacity: 0;
    left: -100%;
  }
  10% {
    opacity: 1;
    left: -100%;
  }
  90% {
    opacity: 1;
    left: 200%;
  }
  100% {
    opacity: 0;
    left: 200%;
  }
}

/* Chrome, Safari, Opera */
@-webkit-keyframes slide-from-right {
  0% {
    transform: translate(150px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
/* Standard syntax */
@keyframes slide-from-right {
  0% {
    transform: translate(150px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.signpost {
  display: block;
  float: left;
  z-index: 390;
  margin-left: -290px;
  top: 45px;
  position: absolute;
  left: 0;
  pointer-events: all;
}

.signpost .scroller {
  position: relative;
  display: block;
  width: 10px;
  height: 242px;
  margin-top: 5px;
  background: #7ca0c5;
  float: right;
  margin-right: 25px;
  animation: arrow-scroll 2.2s infinite;
}
.vertical-text {
  text-transform: uppercase;
  transform: rotate(90deg);
  font-family: "Proza Libre", sans-serif;
  transform-origin: right top 0;
  margin-right: -15px;
  float: right;
  color: #ffffff;
  font-size: 16px;
  width: 250px;
  text-align: right;
}
.category-thought-leadership {
  .vertical-text {
    color: #000;
  }
}
.scroller,
.vertical-text {
  cursor: pointer;
}

/* Common button styles */
.button {
  min-width: 150px;
  display: inline-block;
  margin: 10px 0px 0px 0px;
  @media (min-width: 900px) {
    margin: 1em 0;
  }
  padding: 1em 2em;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 10;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.button:focus {
  outline: none;
}
.button > span {
  vertical-align: middle;
}

/* effect */
.button.button--effect {
  background: #fff;
  color: #37474f;
  overflow: hidden;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  border-radius: 15px;
  font-weight: bold;
}
.button--effect.button--inverted {
  background: none;
  color: #fff;
}
.button--effect.button--transparent {
  background: none;
  border: 2px solid #fff;
  color: #fff;
  transition: color 0.3s, border 0.3s;
}
.button--effect.button--on-white {
  background: #98caec;
  color: #fff;
}
.button--effect::before,
.button--effect::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 100%;
  left: 0;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--effect::before {
  background: #c5e3f9;
}
.button--effect::after {
  background: #98caec;
}
.button--effect.button--on-white::after {
  background: #6d9bbd;
}
.button--effect:hover {
  color: #fff;
  text-decoration: none;
}
.button--effect:hover::before,
.button--effect:hover::after {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.button--effect:hover::after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}
.button--effect.button--transparent:hover {
  border: 2px solid transparent;
}

.banner {
  @extend .cover-image;
  .hideImage {
    display: none;
  }

  @media (max-width: $screen-xs-max) {
    .gneiss-container {
      padding-bottom: 0px;
    }
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    height: 300px;
  }

  @media (min-width: $screen-md-min) {
    &:not(.error, .search, .singular) {
      height: 575px;
    }
  }

  &.tagline {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      .tagline-panel {
        height: auto;
        padding-top: 40px;
      }
    }
  }
  @media (max-height:830px) {
    .header-banner-title{
      .tagline-panel{
        &.dark{
          bottom: 0px;
        }
      }
    }
  }

  &.large {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      height: 420px;
    }
    @media (min-width: 1450px) {
      height: 100vh;
    }
  }
  &.small {
    @media (min-width: $screen-md-min) {
      height: 350px;
    }
    @media (min-width: $screen-lg-min) {
      height: 575px;
    }
  }

  &.fixed {
    @media (max-width: $screen-xs-max) {
      padding-top: 0px;
    }
  }

  h1 {
    &.text-hide {
      border: none;
      margin: 0;
    }
  }

  .brand {
    img {
      height: 32px;
    }
    @media (min-width: $screen-sm-min) {
      float: left;
      height: 100%;
      width: 100%;
    }
  }

  .header__toolbar {
    text-align: right;

    @media (max-width: $screen-xs-max) {
      text-align: left;
    }

    .social {
      @media (min-width: 750px) {
        bottom: 10px;
      }
      @media (min-width: 768px) {
        bottom: 0px;
      }
      @media (min-width: 1200px) {
        //border-left: 1px solid white;
      }
      height: 26px;
      display: inline-block;
      padding: 0px;
      bottom: 3px;

      @media (max-width: $screen-xs-max) {
        display: block;
        text-align: right;
        position: relative;
      }

      .fa-linkedin-in {
        width: 0.675em;
      }

      a {
        position: relative;
        top: 0;
        color: #fff;
        height: 60px;
        margin: 0 15px;
        transition: all ease 0.4s;
        font-size: 1.3em;

        &:hover {
          top: -5px;
        }
      }
    }

    .search-field{
      z-index:10000;
      position:relative;
    }

    .btn-toggle {
      float: right;
      margin-left: 10px;
      z-index:10000;

      .toggle-icon {
        transition: 0.3s all;
      }

      &.cbp-spmenu-push-toleft {
        // left: -250px;
        // @media(min-width: $screen-sm-min) {
        //     left: -200px;
        // }
        // @media(min-width: $screen-md-min) {
        //     left: -160px;
        // }
      }
    }
  }

  .gneiss-container {
    height: 100%;
    padding: $padding-xs;

    @media (min-width: 1024px) {
      padding: 100px;
    }

    @media (min-width: 1200px) {
      padding: 85px;
    }
    @media (min-width: 1400px) {
      padding: 175px;
    }
  }

  .rock-striation {
    position: absolute;
    @media (min-width: 600px) {
      width: 54%;
    }
    width: 85%;
    top: 0px;
    right: 0px;
    z-index: 100;
    pointer-events: none;
  }

  .header__content {
    transition: all 0.6s ease;
    z-index: 6000;
    pointer-events: all;
    @media (min-width: 900px) {
      position: relative;
    }
    @media (min-width: 1000px) and (max-width: 1200px) {
      padding: 0 10px;
    }
    #brand {
      height: 16px;
      left: 11px;
      top: 10px;
      position: absolute;
      z-index: 100;
      @media (min-width: 750px) {
        left: unset;
        position: relative;
        top: unset;
        height: 26px;
      }
      @media (min-width: 900px) {
        height: 60px;
      }
    }

    @media (min-width: 992px) and (max-width: 1200px) {
      #brand {
        margin-left: -50px;
        width: 190px;
        height: 60px;
      }
      #toolbar {
        width: 60%;
        margin-right: -100px;
      }
    }

    @media (min-width: 1200px) and (max-width: 1500px) {
      #brand {
        top: 10px;
      }
      #toolbar {
        margin-right: -85px;
      }
    }

    .main-logo-container {
      background-image: url("../images/logo.png");
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      top: 10px;
      position: relative;
    }
    .single-post-header {
      display: none;
      font-size: 30px;
      padding: 5px 0px;
      font-family: "Proza Libre", sans-serif;
      @media (min-width: 1200px) {
        padding: 5px 190px 18px;
      }
      img {
        width: 60px;
        float: right;
        margin-right: 100px;
      }
    }

    &.fixed {
      @media (max-width: 800px) {
        padding: 9px 15px;
        height: 59px;
      }
      &.post-header-fixed,
      &.events-header-fixed {
        @media (min-width: 992px) {
          border-bottom: unset;
          background: hsla(0, 0%, 100%, 1);
          #brand,
          #toolbar {
            display: none;
          }
          .single-post-header {
            display: block;
            p {
              display: inline-block;
              position: relative;
              top: 7px;
              margin: 0px;
              font-size: 22px;
            }
          }
        }
      }

      .main-logo-container {
        background-image: url("../images/logo-black.png");
        top: 15px;
        width: 64%;
      }

      position: fixed;
      top: 0;
      width: 100%;
      left: 0;
      z-index: 10200;
      background: rgba(255, 255, 255, 0.9);
      padding: 20px $padding-xs;
      border-bottom: 1px solid $brand-secondary;

      @media (max-width: $screen-xs-max) {
        #brand {
          width: 66.6666666667%;
          float: left;
          img {
            max-width: 100%;
            float: left;
            max-height: 40px;
            height: auto;
          }
        }
        #toolbar {
          width: 33.3333333332%;
          float: right;

          .social,
          form {
            display: none;
          }
        }
      }
      @media (min-width: $screen-sm-min) {
        padding: 5px $padding-sm;
      }

      @media (min-width: $screen-md-min) {
        padding: 5px $padding-md;
        //    padding: 5px 250px;
      }
      #brand {
        top: 4px;
      }
      #toolbar {
        width: 60%;
        @media (min-width: $screen-lg-min) {
          width: 75%;
        }
      }
      @media (min-width: 1200px) and (max-width: 1250px) {
        #toolbar {
          width: 91%;
        }
      }
      @media (min-width: 1250px) and (max-width: 1420px) {
        #toolbar {
          width: 90%;
        }
      }
      @media (min-width: 1420px) and (max-width: 1500px) {
        #toolbar {
          width: 89%;
        }
      }

      .brand {
        background: transparent;
      }
      .social {
        background: transparent;
        border-left: unset;
        a {
          height: 40px;
          svg {
            color: black;
          }
        }
      }

      .search-form .fa-search {
        color: black !important;
      }

      .fa-search {
        font-size: 1.6em;
        height: 40px;
      }

      .search-form {
        .search-field {
          height: 40px;
          font-size: 12px;
        }
      }

      .btn-toggle {
        width: 40px;
        height: 40px;
      }

      @media (max-width: $screen-xs-max) {
        .btn-toggle {
          &.cbp-spmenu-push-toleft {
            //left: -250px;
          }
        }
      }

      li a {
        color: black !important;
      }

      .nav-primary {
        li a {
          color: #fff !important;
        }
      }

      .custom-header-holder .search-form .selected {
        @media (min-width: $screen-lg-min) {
          margin-right: 175px;
        }
        .search-field {
          display: block;
          top: 0px !important;
          right: 0px !important;
        }
      }
      .header__toolbar {
        @media (min-width: $screen-sm-min) {
          padding-right: 40px;
          padding-top: 10px;
        }
        @media (min-width: $screen-lg-min) {
          padding-right: 0px;
          padding-top: 0px;
        }
      }
    }
  }

  &.fixed {
    .header-cover {
      .embed-container {
        @media (max-width: 450px) {
          height: 101%;
          width: 100%;
          padding-bottom: 0px;
        }
        @media (min-width: 451px) and (max-width: 555px) {
          height: 115%;
          width: 100%;
        }
        @media (min-width: 556px) and (max-width: 600px) {
          height: 124%;
          width: 100%;
        }
        @media (min-width: 601px) and (max-width: 800px) {
          height: 164%;
          width: 100%;
          bottom: 80px;
        }
      }
    }
  }

  .header-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -10;
    @media (max-width: 450px) {
      width: 117%;
    }
    @media (max-width: 800px) {
      height: 100%;
    }
    .embed-container {
      height: 100%;
      max-width: 140%;
      @media (max-width: 331px) {
        width: 140%;
      }
      @media (min-width: 332px) and (max-width: 349px) {
        width: 132%;
      }
      @media (min-width: 350px) and (max-width: 399px) {
        width: 132%;
      }
      @media (min-width: 400px) and (max-width: 400px) {
        width: 120%;
      }
      @media (min-width: 401px) and (max-width: 419px) {
        width: 144%;
      }
      @media (min-width: 420px) and (max-width: 431px) {
        width: 136%;
      }
      @media (min-width: 432px) and (max-width: 451px) {
        width: 130%;
      }
      @media (min-width: 452px) and (max-width: 469px) {
        width: 125%;
      }
      @media (min-width: 470px) and (max-width: 489px) {
        width: 120%;
      }
      @media (min-width: 490px) and (max-width: 500px) {
        width: 117%;
      }
      @media (min-width: 501px) and (max-width: 521px) {
        width: 121%;
      }
      @media (min-width: 522px) and (max-width: 543px) {
        width: 116%;
      }
      @media (min-width: 544px) and (max-width: 568px) {
        width: 111%;
      }
      @media (min-width: 567px) and (max-width: 589px) {
        width: 107%;
      }
      @media (min-width: 590px) and (max-width: 618px) {
        width: 102%;
      }
      @media (min-width: 619px) and (max-width: 630px) {
        width: 100%;
      }
      @media (min-width: 631px) and (max-width: 642px) {
        width: 100%;
        height: 102%;
      }
      @media (min-width: 643px) and (max-width: 667px) {
        width: 100%;
        height: 106%;
      }
      @media (min-width: 668px) and (max-width: 692px) {
        width: 100%;
        height: 110%;
      }
      @media (min-width: 693px) and (max-width: 717px) {
        width: 100%;
        height: 114%;
      }
      @media (min-width: 718px) and (max-width: 742px) {
        width: 100%;
        height: 118%;
      }
      @media (min-width: 743px) and (max-width: 749px) {
        width: 100%;
        height: 122%;
      }
      @media (min-width: 750px) and (max-width: 767px) {
        width: 100%;
        height: 115%;
      }
      @media (min-width: 768px) and (max-width: 793px) {
        width: 100%;
        height: 105%;
      }
      @media (min-width: 794px) and (max-width: 800px) {
        width: 100%;
        height: 108%;
      }
    }
  }

  #map {
    height: 100%;
    width: 100%;
  }

  .tagline-panel {
    padding: 10px 25px;
    position: absolute;
    bottom: $padding-xs;
    pointer-events: all;
    @media (min-width: 768px) {
      padding: 10px 40px;
    }
    &.dark {
      @extend .cover-image;
      color: #fff;
      opacity: 1;
      z-index: 5000;
      @media (min-width: 768px) {
        max-width: 600px;
        left: 80px;
        bottom: 40px;
      }

      @media (min-width: 992px) {
        max-width: 850px;
      }

      @media (min-width: 1450px) {
        max-width: 875px;
        left: 250px;
        bottom: 250px;
        padding-left: 0px;
      }

      @media (min-width: 1675px) {
        max-width: 875px;
        left: 250px;
        bottom: 140px;
        padding-left: 0px;
      }

      h1,
      h2 {
        font-weight: 800;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 45px;
        line-height: 1;
      }
      h1 {
        @media (max-width: 350px) {
          font-size: 19px;
          margin-top: -14px;
          margin-bottom: 15px;
        }
        @media (min-width: 350px) and (max-width: 400px) {
          font-size: 20px;
          margin-top: 0px;
          margin-bottom: 17px;
        }
        @media (min-width: 400px) and (max-width: 500px) {
          font-size: 22px;
        }
        @media (min-width: 636px) and (max-width: 768px) {
          padding-right: 135px;
        }
        @media (min-width: 992px) and (max-width: 1200px) {
          font-size: 50px;
          font-weight: 800;
        }
        @media (min-width: 1200px) {
          font-size: 70px;
          font-weight: 800;
        }
      }

      .explore-gneiss-button {
        cursor: pointer;
        pointer-events: all;
      }

      .btn-primary {
        z-index: 100;
        width: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 120px;
        height: 60px;
        border: 2px solid white;
        border-radius: 12px;
        background-color: unset;
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 700;
        text-transform: unset;
        padding: 10px 20px;
        margin-bottom: 5px;
        position: relative;
        overflow: hidden;
        @media (min-width: 900px) {
          float: left;
        }
      }
      @media (min-width: 350px) {
        display: block;
        padding: 18px 24px;
        margin-bottom: 0px;
        font-size: 16px;
      }
      @media (min-width: 400px) {
        display: block;
        padding: 5px 24px;
      }
      @media (min-width: 450px) {
        display: block;
      }
      @media (min-width: 500px) {
        display: block;
      }

      strong {
        z-index: 100;
        color: $brand-highlight;
      }

      &:before {
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        z-index: -1;

        @media (min-width: $screen-sm-min) {
          opacity: 0.75;
        }
      }
    }

    &.light {
      @extend .cover-image;
      color: #7ca0c5;
      opacity: 1;
      z-index: 5000;
      @media (min-width: 768px) {
        max-width: 600px;
        left: 0px;
        bottom: unset;
      }

      @media (min-width: 992px) {
        max-width: 875px;
        min-height: 230px;
      }

      @media (min-width: 1200px) {
        max-width: 875px;
        left: 250px;
        bottom: 140px;
        padding-left: 0px;
      }

      h1,
      h2 {
        font-weight: 800;
        text-transform: uppercase;
        color: #000;
        line-height: 1;
      }
      h1 {
        @media (max-width: 350px) {
          font-size: 19px;
          margin-top: -14px;
        }
        @media (min-width: 350px) and (max-width: 400px) {
          font-size: 20px;
          margin-top: 0px;
        }
        @media (min-width: 400px) and (max-width: 500px) {
          font-size: 22px;
        }
        @media (min-width: 636px) and (max-width: 768px) {
          padding-right: 135px;
        }
        @media (min-width: 992px) and (max-width: 1200px) {
          font-size: 50px;
          font-weight: 800;
        }
        @media (min-width: 1200px) {
          font-size: 70px;
          font-weight: 800;
        }
      }

      a {
        z-index: 100;
        float: left;
        width: 240px;
        display: inline-block;
        text-align: center;
        min-width: 120px;
        border: 2px solid black;
        border-radius: 12px;
        background-color: unset;
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 700;
        text-transform: unset;
        padding: 18px 24px;
      }
      .scrollNav {
        border: unset;
        float: unset;
        width: auto;
        display: unset;
        border-radius: 0;
        padding: 0;
        .vertical-text {
          color: black;
        }
      }
      @media (min-width: 1000px) {
        display: block;
      }

      strong {
        z-index: 100;
        color: $brand-highlight;
      }

      &:before {
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        z-index: -1;

        @media (min-width: $screen-sm-min) {
          opacity: 0.75;
        }
      }
    }

    .tagline-container {
      display: block;
    }

    hr {
      width: 64%;
      margin: 25px auto;
      display: inline-block;
      border-color: $brand-highlight;

      &.large {
        width: 100%;
        margin: 0;
      }
    }

    @media (max-width: $screen-xs-max) {
      position: relative;
      bottom: auto;
      right: auto;
      left: auto;
      width: 100%;
      display: block;

      hr {
        width: 49%;
      }

      a {
        width: 50%;
      }
    }

    @media (min-width: $screen-md-min) {
      h1 {
        font-size: 70px;
        font-weight: 300;
      }
    }
  }

  .slideInAnimationElement {
    transition: all ease-in-out 1s;
    transition-delay: 0.5s;
    position: relative;
    right: 0px;
  }

  .slideInAnimation {
    right: 2000px;
  }

  .nav-primary {
    li {
      a {
        text-transform: uppercase;
        font-family: $font-heading;
        color: #fff;
        padding: 20px 15px;
        &:hover {
          background-color: #23527c;
        }
      }
    }

    .nav {
      float: left;
      width: 100%;
      height: 100%;
    }

    @media (min-width: 1200px) {
      margin-top: 10px;
      background: rgba(0, 0, 0, 0.7);
      display: inline-block;
      width: calc(100% - 30px);
      left: 15px;
      opacity: 0;
      position: relative;
      height: 0;
      transition: opacity 0.4s, height 0.2s linear;
      li {
        display: inline-block;
        float: left;
        height: 130px;
        z-index: 9000;
        @media (min-width: $screen-sm-min) {
          width: 33.3333333334%;
        }
        @media (min-width: $screen-md-min) {
          width: 16.6666666667%;
        }
        a {
          z-index: 10000;
          text-align: center;
          color: #fff;
          position: relative;
          display: block;
          height: 130px;
          padding: 105px 0 5px 0;
          background-color: transparent;
          border: none;

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 30px;
            z-index: -1;
            background-color: rgba(0, 0, 0, 0.6);
          }
        }

        &:hover {
          a {
            transition: all 0.35s ease;
            background-color: rgba(15, 86, 109, 0.8);
          }
        }
      }
      &.feature {
        transition: opacity 0.4s, height 0.2s linear;
        opacity: 1;
        @media (min-width: $screen-sm-min) {
          height: 260px;
        }
        @media (min-width: $screen-md-min) {
          height: 130px;
        }
      }
    }
  }

  .disable {
    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}

.background-panel {
  //background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  z-index: 1000;

  @media (max-width: $screen-xs-max) {
    position: relative;
    padding: 15px 0px;
  }

  @media (min-width: $screen-sm-min) {
    padding-top: 25px;
    height: 75px;
    margin-top: -150px;
  }

  @media (min-width: $screen-md-min) {
    &:not(.large) {
      height: 75px;
      margin-top: 0px;
      padding-top: 20px;
    }
  }

  @media (min-width: 1450px) {
    &.large {
      height: 600px;
      margin-top: -600px;
    }
  }

  .team__tabs,
  .media__tabs {
    text-align: center;
    width: 90%;
    margin: 0 auto;

    a {
      font-weight: 500;
      font-family: $font-heading;
      text-transform: uppercase;
      margin: 0 8px;
      text-decoration: none;
      color: #fff;
      font-size: 18px;

      &:hover {
        color: $brand-highlight;
      }

      &.active {
        color: $brand-highlight;
        border-bottom: 2px solid $brand-highlight;
      }

      @media (max-width: $screen-xs-max) {
        display: block;
        margin: 8px 0px;
      }
      @media (min-width: $screen-md-min) {
        font-size: 25px;
      }
    }
  }
}

/* New Navigation */

.dark {
  #toolbar {
    .new-navigation li {
      a {
        color: white;
      }
      &.active {
        a {
          position: relative;
        }
        &:after {
          content: "";
          display: block;
          position: relative;
          height: 16px;
          bottom: 8px;
          width: 100%;
          background: url("../images/active-underline.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position-x: center;
        }
      }
    }
  }
}

.light,
.single-team {
  #toolbar {
    .new-navigation li {
      a {
        color: black;
        font-size: 18px;
      }
      &.active {
        a {
          position: relative;
        }
        &:after {
          content: "";
          display: block;
          position: relative;
          height: 16px;
          bottom: 8px;
          width: 100%;
          background: url("../images/active-underline.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position-x: center;
        }
      }
    }
  }
}

.single-team {
  .main-logo-container {
    background-image: url("../images/logo-black.png") !important;
  }
  .signpost {
    top: 170px;
    left: -80px;
  }
}

.team {
  .banner .tagline-panel {
    @media(min-width:1200px) {
      left: 220px;
    }
    @media (min-width: 1000px) {
      max-width: 500px;
    }
  }
}

#toolbar {
  padding: 0;

  svg {
    &.fa-search {
      font-size: 21px;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 15px;
  }

  .custom-header-holder {
    display: inline-block;
    padding: 0 10px;
    @media (max-width: 1200px) {
      padding: auto;
      background: transparent;
      display: block;
    }
  }

  .new-navigation {
    padding-left: 0;
    display: inline-block;
    width: auto;
    margin-bottom: 0;

    @media (max-width: 1200px) {
      display: none;
    }

    li {
      list-style-type: none;
      display: inline-block;
      height: 60px;
      line-height: 60px;
      margin-right: 8px;
      transition: all 0.4s ease;
      position: relative;

      &:hover {
        transform: translateY(-5px);
      }

      a {
        line-height: 60px;
        text-transform: uppercase;
        padding: 10px 15px;
        font-weight: 700;
        font-size: 18px;

        @media(max-width:1250px){
          padding: 10px 12px;
        }

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }

      &:focus,
      &:hover,
      &:active {
        a {
          text-decoration: none;
        }
      }

      &.active {
        a {
        }
        &:focus,
        &:hover,
        &:active {
          text-decoration: none;
        }
      }
    }
  }
  .btn-toggle {
    &.cbp-spmenu-push {
      display: none;

      @media (max-width: 1200px) {
        display: inline;
      }
    }
  }

  .search-form {
    label {
      width: 50px;
      transition: 0.3s;
      &.selected {
      }

      @media (max-width: 1200px) {
        width: auto;
      }

      @media (max-width: 767px) {
        width: calc(100% - 50px);
      }
    }

    .fa-search {
      margin-left: 10px;
      transition: 0.3s;
      display: none;
      &:hover {
        transform: translateY(-5px);
        cursor: pointer;
      }
      @media (min-width: 1200px) {
        display: block;
      }
    }

    .selected {
      .fa-search {
        margin-left: 20px !important;
      }
      .search-field {
        @media (min-width: $screen-lg-min) {
          width: 320px;
          right: 240px;
          position: relative;
          top: 40px;
        }
      }
    }

    .search-field {
      background: transparent;
      margin-right: 0;
      outline: 0;
      box-shadow: none;

      &::placeholder {
        color: white;
      }
      @media (max-width: 1200px) {
        margin-right: 15px;
        background: rgba(234, 234, 234, 0.6);
      }

      &:focus,
      &:active {
        outline: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.fixed {
  .new-navigation,
  #toolbar .custom-header-holder {
    background-color: transparent;
  }

  #toolbar {
    .new-navigation li {
      margin-right: 35px;
      // remove
    }
    .search-form {
      .search-field {
        color: black;
        &::placeholder {
          color: black;
        }
      }
    }
  }
}

#brand {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: start;

  @media (max-width: 768px) {
    height: auto;
    margin: 0 auto;
    display: block;
  }
}

.banner {
  .gneiss-container.custom-gneiss-cont {
    padding-top: 20px;
    overflow: hidden;
    z-index: 400;
    pointer-events: none;

    .signpost {
      display: none;
      @media (min-width: 1200px) {
        display: block;
      }
    }

    .header-banner-title {
      @media (min-width: 900px) {
        height: auto;
      }
    }

    .viewAll {
      display: inline-block;
      position: absolute;
      right: 60px;
      top: 260px;
      &-title {
        text-transform: uppercase;
        h2 {
          font-size: 20px;
          margin-left: 20px;
          color: #f6598b;
        }
      }
      &-container {
        background-color: rgba(255, 255, 255, 0.7);
        padding: 20px;
        p {
          margin: 8px 0;
          color: #343e47;
          text-transform: uppercase;
          font-family: "Proza Libre", sans-serif;
          &.active-link {
            color: #f6598b;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
        a {
          pointer-events: all;
          &:hover {
            text-decoration: none;
            p {
              color: #f6598b;
            }
          }
        }
      }
    }
    .contact-details {
      &:before {
        content: "";
        //width: 617px;
        width: 100%;
        height: 378px;
        background: black;
        border-radius: 150px;
        filter: blur(50px);
        opacity: 0.5;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
      }
      &-title {
        width: 500px;
      }
      position: absolute;
      right: 65px;
      color: #fff;
      top: 200px;
      p {
        display: inline-block;
        width: 90px;
        font-size: 20px;
      }
      .footer-right-blue {
        width: 50%;
        margin: 0 auto;
        border-bottom: 3px solid #7ca0c5;
        position: relative;
        display: inline-block;
        @media (min-width: 1000px) {
          width: 50%;
        }
        @media (min-width: 1500px) {
          width: 60%;
          margin: unset;
          display: inline-block;
        }
      }
      .footer-text-small {
        padding: 0px;
        display: inline-block;
        svg {
          font-size: 30px;
        }
        a {
          color: white;
          font-size: 26px;
        }
      }
      .contact-details-box {
        margin-top: 30px;
      }
    }
  }

  .custom-row {
    @media (max-width: 992px) {
      display: flex !important;
      align-items: center;
    }
    @media (max-width: 768px) {
      display: block !important;
    }
  }
}

.about,
.services,
.sectors {
  .tagline-panel {
    &.light {
      h1 {
        font-size: 26px;
        margin-bottom: 0px;
        @media (min-width: 636px) and (max-width: 768px) {
          padding-right: 135px;
        }
        @media (min-width: 992px) and (max-width: 1200px) {
          font-size: 50px;
          font-weight: 800;
        }
        @media (min-width: 1200px) {
          font-size: 70px;
          font-weight: 800;
        }
      }
    }
  }
}


.single-service, .single-case-study {
  header.light {
    position: relative;
    top: 0px;
  }
  .banner {
    @media (min-width: 1200px) {
      height: 100px;
    }
    .tagline-panel {
      h1 {
        @media (min-width: 1200px) {
          font-size: 47px;
        }
        text-transform: unset;
      }
    }
  }
  .tagline-panel,
  .light {
    @media (min-width: 1200px) {
      top: 205px;
      .signpost {
        top: 100px;
      }
    }
  }
  .header__content {
    background-color: rgba(255, 255, 255, 0.1);
    transition: unset;
  }
  header.banner {
    transition-delay: unset;
    .header__content {
      opacity: 1;
    }
  }
  .bottom-box-shadow {
    box-shadow: unset;
  }
  @media (min-width: 1500px) {
    .section--two-fifty-three {
      //padding: 40px 12.5% 40px 2.5%;
    }
  }
  .rock-striation {
    transition-duration: 0s !important;
    transition-property: none !important;
  }
  @media (min-width: 1500px) {
    .signpost {
      display: block;
      float: left;
      z-index: 390;
      margin-left: -110px;
      top: 250px;
      position: absolute;
      left: 0;
      pointer-events: all;
      .vertical-text {
        color: black;
      }
    }
  }
  .wrap {
    opacity: 1;
    transition: all 0s !important;
    transition-delay: 0s !important;
    left: 0;
  }
}

.single-sector {
  .banner {
    @media (min-width: 1200px) {
      .tagline-panel,
      .light {
        top: 255px;
        .signpost {
          top: 180px;
          margin-left: -380px;
        }
      }
      .tagline-panel {
        max-width: 1100px;
        left: 285px;
        h2 {
          font-size: 70px;
        }
      }
      .header-cover {
        position: fixed;
      }
    }
  }
}

.sectors {
  .banner {
    @media (min-width: 1200px) {
      height: 560px;
    }
  }
  @media (min-width: 1200px) {
    .tagline-panel,
    .light {
      top: 240px;
      .signpost {
        top: 190px;
        .scroller {
          position: relative;
          display: block;
          width: 10px;
          height: 80px;
        }
      }
    }
    .tagline-panel {
      &.light {
        h1 {
          text-transform: unset;
          font-size: 50px;
        }
      }
    }
  }
}

.single-post,
.single-events {
  .banner {
    @media (min-width: 1200px) {
      height: 420px;
    }
  }
  @media (min-width: 1200px) {
    .tagline-panel,
    .light {
      top: 240px;
      .signpost {
        top: 110px;
        .scroller {
          position: relative;
          display: block;
          width: 10px;
          height: 80px;
        }
      }
    }
    .tagline-panel {
      &.light {
        h1 {
          text-transform: unset;
          font-size: 40px;
        }
      }
    }
  }
  @media (min-width: 1500px) {
    .banner .gneiss-container .tagline-panel {
      max-width: 1000px;
    }
  }
}

.single-events,
.single-post {
  .banner {
    height: auto;
    .tagline-panel {
      position: relative;
      min-height: unset;
      top: 0px;
      @media (min-width: 1500px) {
        left: 90px;
        padding-top: 160px;
        max-width: 1000px;
      }
      .signpost {
        top: 180px;
      }
    }
    .gneiss-container {
      @media (min-width: 1500px) {
        padding: 0px 175px 35px;
      }
    }
  }
}

.archive,
.blog {
  .banner {
    height: 450px;
  }
  .tagline-panel {
    .signpost {
      top: 110px;
      .scroller {
        height: 100px;
      }
    }
  }
  header {
    box-shadow: 0 15px 22px -9px #cccccc54;
    overflow: hidden;
    position: relative;
  }
}

.post-type-archive-events,
.blog {
  .banner .tagline-panel {
    bottom: unset;
    top: 200px;
  }
}

.topBarSpacer {
  display: none;
  height: 62px;
}
.fixed {
  .topBarSpacer {
    display: block;
  }
}

.category {
  .banner {
    .tagline-panel {
      bottom: 80px;
      h1 {
        max-width: 700px;
      }
    }
  }
}

.covid-banner {
  background: $serviceYellow;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;

  div {
    color: #fff;
    font-size: 2rem;

    a {
        color: #fff;
        font-size: 2rem;
    }
  }

  > a {
      background: #CCC;
      color: #353d47;
      font-size: 1.5rem;
      padding: 5px 15px;
  }
}

